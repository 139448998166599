import {
  CategoriesContextType,
  useCategoriesContext
} from '@/context/CategoriesContext'

const useCategories = (): CategoriesContextType => {
  const { categories } = useCategoriesContext()

  return {
    categories
  }
}

export default useCategories
