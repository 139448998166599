import classNames from 'classnames'
import { PropsWithChildren } from 'react'

export type BadgeSize = 'default' | 'medium'

export type BadgeType = 'primary' | 'secondary' | 'danger'

export type Shape = 'default' | 'circle'

export type BadgeProps = PropsWithChildren<{
  type?: BadgeType
  size?: BadgeSize
  shape?: Shape
  className?: string
}>

const Badge = ({
  children,
  size = 'default',
  type = 'primary',
  shape = 'default',
  className
}: BadgeProps) => {
  return (
    <span
      className={classNames('font-firago font-medium', className, {
        'bg-yellow text-black': type === 'primary',
        'bg-black text-white': type === 'secondary',
        'bg-red text-white': type === 'danger',
        'rounded-md': shape === 'default',
        'flex h-[23px] w-[23px] items-center justify-center rounded-full':
          shape === 'circle',
        'px-2 py-0.5 text-[13px]': size === 'default',
        'px-2 py-1.5 text-sm': size === 'medium'
      })}
    >
      {children}
    </span>
  )
}

export default Badge
