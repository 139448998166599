import { useState } from 'react'
import useTranslate from './useTranslate'

const usePasswordStrengthChecker = (password: string) => {
  const [passedRules, setPassedRules] = useState<any[]>([])
  const [failedRules, setFailedRules] = useState<any[]>([])
  const t = useTranslate()

  const rules = [
    {
      label: t('password.at_least_eight_chars'),
      passed: password.length >= 8,
      score: 2
    },
    {
      label: t('password.at_least_one_uppercase'),
      passed: /[A-Z]/.test(password),
      score: 2
    },
    {
      label: t('password.at_least_one_lower'),
      passed: /[a-z]/.test(password),
      score: 2
    },
    {
      label: t('password.at_least_one_digit'),
      passed: /\d/.test(password),
      score: 2
    },
    {
      label: t('password.at_least_one_special_character'),
      passed: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password),
      score: 2
    }
  ]

  // Filter the rules to find the ones that passed and failed

  const passed = rules.filter((rule) => rule.passed)
  const failed = rules.filter((rule) => !rule.passed)
  const totalScore = passed.reduce((prev, current) => prev + current.score, 0)
  const getScoreWord = (score: number) => {
    if (score <= 4) {
      return t('password.weak')
    } else if (score <= 8) {
      return t('password.moderate')
    } else if (score <= 10) {
      return t('password.strong')
    }
  }

  const getScoreKey = (score: number) => {
    if (score <= 4) {
      return 'weak'
    } else if (score <= 8) {
      return 'moderate'
    } else if (score <= 10) {
      return 'strong'
    }
  }

  return {
    passed,
    failed,
    scoreKey: getScoreKey(totalScore),
    totalScore,
    percentage: (totalScore / 10) * 100,
    scoreWord: getScoreWord(totalScore)
  }
}

export default usePasswordStrengthChecker
