import Status, { ProcessStatus, StatusProps } from '@/components/Status'
import { notification } from 'antd'
import { ArgsProps } from 'antd/es/notification/interface'
import { CloseCircle } from 'iconsax-react'

notification.config({
  top: 80
})

const notifications = {
  success: ({
    icon,
    message,
    description,
    role,
    size = 'default'
  }: ArgsProps & { size?: StatusProps['size'] }) =>
    notification.success({
      icon: <Status size={size} status={ProcessStatus.SUCCESS} />,
      closeIcon: <CloseCircle />,
      className: '!font-firago !p-4',
      message,
      description,
      role
    }),
  error: ({
    message,
    description,
    role,
    icon,
    size
  }: ArgsProps & { size?: StatusProps['size'] }) =>
    notification.error({
      icon: icon ?? <Status size={size} status={ProcessStatus.ERROR} />,
      closeIcon: <CloseCircle />,
      className: '!font-firago !p-4',
      message,
      description,
      role
    }),
  warning: ({
    icon,
    message,
    description,
    role,
    size
  }: ArgsProps & { size?: StatusProps['size'] }) =>
    notification.warning({
      icon: icon ?? <Status size={size} status={ProcessStatus.WARNING} />,
      closeIcon: <CloseCircle />,
      className: '!font-firago !p-4',
      message,
      description,
      role
    })
}

export default notifications
