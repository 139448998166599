import classNames from 'classnames'
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip'

export type TooltipProps = {
  id: string
  place: PlacesType
  content: string
  className?: string
  classNameArrow?: string
}

const Tooltip = ({ className, ...props }: TooltipProps) => {
  return (
    <ReactTooltip
      className={classNames(
        className,
        '!z-[1051] !rounded-lg !bg-black !font-firago !text-sm'
      )}
      {...props}
    />
  )
}

export default Tooltip
