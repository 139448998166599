import Button from '@/components/Button'
import Card from '@/components/Card'
import Divider from '@/components/Divider'
import Dropdown from '@/components/Dropdown'
import IconButton from '@/components/IconButton'
import Image from '@/components/Image'
import Link from '@/components/Link'
import Menu from '@/components/Menu'
import { SachemoLogoEn, SachemoLogoGeo } from '@/components/SVG'
import Tooltip from '@/components/Tooltip'
import { getUserProfilePages } from '@/constants/profile'
import useCart from '@/hooks/useCart'
import useCurrentUser from '@/hooks/useCurrentUser'
import useHeaderRef from '@/hooks/useHeaderRef'
import useMobileNavigation from '@/hooks/useMobileNavigation'
import useModal from '@/hooks/useModal'
import { useSignIn } from '@/hooks/useSignIn'
import useTranslate from '@/hooks/useTranslate'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { Brand } from '@/types/brand'
import { getBrandLogoUrl, getUserInitials } from '@/utils/utils'
import { Avatar } from 'antd'
import classNames from 'classnames'
import {
  AddCircle,
  Bag2,
  Heart,
  LogoutCurve,
  NotificationBing,
  Shop,
  User
} from 'iconsax-react'
import { useLocale } from 'next-intl'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { CustomScroll } from 'react-custom-scroll'
import { MobilePopupWrapper } from '../MobilePopup'
import HeaderSearch from './HeaderSearch'

export type HeaderProps = {
  className?: string
  keyword?: string
  suggestions?: string[]
  popularSearches?: string[]
  latestSearches?: string[]
  brands?: any[]
  onSearch?: (value?: string) => void
}

const Header = ({
  className,
  suggestions,
  latestSearches,
  popularSearches,
  keyword,
  brands,
  onSearch
}: HeaderProps) => {
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>()
  const onSearchChange = (value: string) => setSearchKeyword(value)
  const { open } = useModal()
  const t = useTranslate()
  const router = useRouter()
  const locale = useLocale()
  const pathname = usePathname()
  const ref = useHeaderRef()
  const [userDropdownVisible, setUserDropdownVisible] = useState(false)
  const [brandsDropdownVisible, setBrandsDropdownVisible] = useState(false)
  const userProfileRef = useRef(null)
  const brandsProfileRef = useRef(null)
  const { closeMenu, closeUser, closeSearch } = useMobileNavigation()
  const profileLinks = getUserProfilePages({ except: ['profile'] })

  useEffect(() => setSearchKeyword(keyword), [keyword])

  const onSuggestion = (keyword: string) => {
    onSearchChange(keyword)
    onSearch?.(keyword)
  }

  const { currentUser } = useCurrentUser()
  const { cart } = useCart()

  const { logout } = useSignIn()

  const handleLogout = async () => {
    setUserDropdownVisible(false)
    logout()
  }

  const handleProfileClick = () => {
    router.replace('/profile')
    setUserDropdownVisible(false)
  }

  const handleBrandClick = (brand: any) => {
    router.replace(`/profile/brands/${brand.brand_id}/profile`)
    setBrandsDropdownVisible(false)
  }

  const handleAddProduct = (brand: Brand) => {
    router.replace(`/profile/brands/${brand.brand_id}/products/add`)
    setBrandsDropdownVisible(false)
  }

  useUpdateEffect(() => {
    if (brandsDropdownVisible) {
      closeMenu()
      closeUser()
      closeSearch()
    }
  }, [brandsDropdownVisible])

  useEffect(() => {
    return () => {
      setUserDropdownVisible(false)
    }
  }, [])

  return (
    <>
      <header
        ref={ref}
        className={classNames(
          'flex w-full border-b border-gray-300 bg-white pl-4 sm:px-6',
          className
        )}
      >
        <div className="flex w-full items-center sm:w-auto">
          <Link
            className="block h-full p-6 pl-0 sm:border-r sm:border-gray-300 sm:pl-6"
            href="/"
          >
            {locale === 'ka' && <SachemoLogoGeo />}
            {locale === 'en' && <SachemoLogoEn />}
          </Link>
          <Link
            className="ml-auto mr-3.5 !p-0 font-firago text-sm sm:mx-6"
            href={
              pathname === `/${locale}`
                ? '/'
                : pathname.replace(locale, '').slice(1)
            }
            locale={locale === 'ka' ? 'en' : 'ka'}
            scroll={false}
          >
            {locale === 'ka' ? 'ENG' : 'GEO'}
          </Link>
        </div>
        <div className="hidden flex-1 items-center justify-center px-4 sm:flex">
          <HeaderSearch
            value={searchKeyword}
            suggestions={suggestions}
            latestSearches={latestSearches}
            popularSearches={popularSearches}
            onSearchChange={onSearchChange}
            onSearch={() => onSearch?.(searchKeyword)}
            onClear={() => setSearchKeyword('')}
            onSuggestion={onSuggestion}
          />
        </div>
        <div className="flex">
          <div
            className="relative flex h-full items-center border-gray-300 pl-2 pr-0 before:absolute before:left-0 before:block before:h-[30px] before:w-[1px] before:bg-gray-300 before:content-[''] sm:border-r sm:px-3.5 sm:before:hidden"
            data-tooltip-id="favs"
          >
            <Button
              icon={<Heart size={24} />}
              ghost
              shape="circle"
              className="!h-auto !min-w-[0px] !p-2.5"
            />
            <Tooltip
              id="favs"
              place="bottom"
              content={t('common.favorites')}
              className="-mt-3 max-sm:hidden"
            />
          </div>

          {currentUser && (
            <div
              className="hidden h-full items-center border-r border-gray-300 px-3.5 sm:flex"
              data-tooltip-id="notification"
            >
              <span className="relative">
                <span className="absolute right-2 top-2 z-10 h-3 w-3 rounded-full border border-white bg-red" />
                <IconButton
                  icon={<NotificationBing size={24} />}
                  type="ghost"
                  className="!p-2.5 hover:bg-gray-200"
                />
                <Tooltip
                  id="notification"
                  place="bottom"
                  content={t('common.notifications')}
                  className="-mt-3"
                />
              </span>
            </div>
          )}

          {currentUser && !!brands?.length && (
            <div
              ref={brandsProfileRef}
              className="relative h-full items-center"
            >
              <span
                className={classNames(
                  'absolute bottom-[-1px] left-0 w-full border-b max-lg:!border-gray-300',
                  {
                    'border-white': brandsDropdownVisible,
                    'border-gray-300': !brandsDropdownVisible
                  }
                )}
              />
              <MobilePopupWrapper
                className="!h-[calc(100vh-65px)] lg:!hidden !top-[65px]"
                isOpen={brandsDropdownVisible}
              />
              <Dropdown
                open={brandsDropdownVisible}
                trigger={['click']}
                disabled={brands?.length === 1}
                rootClassName="!top-[64px] sm:!top-[68px] !fixed !mt-[1px] !right-0 w-full sm:w-[400px] !rounded-bl-md max-sm:!rounded-br-md !border-0 !border-l !border-b !py-0"
                getPopupContainer={() => brandsProfileRef.current!}
                dropdownRender={() => (
                  <CustomScroll className="max-lg:-mx-3 lg:-mr-3 [&_.rcs-inner-handle]:bg-black [&_.rcs-inner-handle]:bg-opacity-50">
                    <div className="lg:mr-3 max-h-[500px] max-lg:py-2">
                      {brands?.map((brand, indx) => (
                        <div
                          key={brand.id}
                          className="lg:-mx-5 flex items-center border-b p-3 last:border-b-0 sm:px-5 sm:py-4"
                        >
                          <Card
                            className={classNames('!mt-0 w-[360px] !p-4', {
                              'mt-2': indx > 0
                            })}
                            title={`${brand.name}`}
                            label={`${t('common.balance')}: ${
                              brand?.balance ?? 0
                            } ₾`}
                            imageClassName="!w-11 !h-11"
                            icon={
                              !!getBrandLogoUrl(brand) ? (
                                <Image
                                  src={getBrandLogoUrl(brand)}
                                  width={44}
                                  height={44}
                                  alt="Avatar"
                                  className="cursor-pointer !h-full"
                                  objectFit="cover"
                                />
                              ) : (
                                <span>
                                  <User size={20} />
                                </span>
                              )
                            }
                            onClick={() => handleBrandClick(brand)}
                          />
                          <div
                            className="pl-3 pr-1"
                            data-tooltip-id={`add_product_${brand.id}`}
                          >
                            <IconButton
                              size="small"
                              icon={<AddCircle size={17} />}
                              onClick={() => handleAddProduct(brand)}
                            />
                            <Tooltip
                              id={`add_product_${brand.id}`}
                              place="bottom-end"
                              content={t('common.add_product')}
                              classNameArrow="!right-5 !left-auto"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </CustomScroll>
                )}
                onOpenChange={setBrandsDropdownVisible}
              >
                <div
                  className="flex h-full items-center border-gray-300 pl-1 pr-2 sm:border-r sm:px-3.5"
                  data-tooltip-id="shop"
                >
                  <span className="relative">
                    <IconButton
                      icon={<Shop size={24} />}
                      type="ghost"
                      className="!p-2.5 hover:bg-gray-200"
                      onClick={() =>
                        brands?.length === 1 && handleBrandClick(brands[0])
                      }
                    />

                    <Tooltip
                      id="shop"
                      place="bottom"
                      content={t('common.brands')}
                      className="-mt-3 max-sm:hidden"
                    />
                  </span>
                </div>
              </Dropdown>
            </div>
          )}

          {/* <div className="hidden h-full items-center sm:flex">
            <Link href="/cart">
              <span className="relative">
                <Button
                  icon={<Bag2 size={24} />}
                  ghost
                  shape="round"
                  className="!h-auto !p-2.5"
                >
                  {cart?.total_quantity ?? 0}
                </Button>
                <Tooltip
                  id="cart"
                  place="bottom"
                  content={t('common.cart')}
                  className="-mt-3"
                />
              </span>
            </Link>
          </div> */}

          <div
            className="relative flex h-full items-center border-gray-300 pl-2 max-lg:pr-2 pr-0 before:absolute before:left-0 before:block before:h-[30px] before:w-[1px] before:bg-gray-300 before:content-[''] sm:border-r sm:px-3.5 sm:before:hidden"
            data-tooltip-id="cart"
          >
            <Link href="/cart">
              <>
                <Button
                  icon={<Bag2 size={24} />}
                  ghost
                  shape="circle"
                  className="!h-auto !px-2.5 !p-2 lg:!p-2.5 !rounded-[52px]"
                >
                  {cart?.total_quantity ?? 0}
                </Button>
                <Tooltip
                  id="cart"
                  place="bottom"
                  content={t('common.cart')}
                  className="-mt-3 max-sm:hidden"
                />
              </>
            </Link>
          </div>

          {!currentUser && (
            <div className="hidden h-full items-center px-3.5 sm:flex">
              <Button
                type="link"
                className="!px-2.5"
                onClick={() => open('AUTH')}
              >
                {t('common.login')}
              </Button>
            </div>
          )}

          {currentUser && (
            <div
              ref={userProfileRef}
              className="relative hidden h-full items-center sm:flex"
            >
              <span
                className={classNames(
                  'absolute bottom-[-1px] left-0 w-[102px] border-b',
                  {
                    'border-white': userDropdownVisible,
                    'border-gray-300': !userDropdownVisible
                  }
                )}
              />
              <Dropdown
                open={userDropdownVisible}
                trigger={['click']}
                rootClassName="!top-[68px] !mt-[1px] !fixed !right-0 w-[400px] !rounded-bl-md !border-0 !border-l !border-b"
                getPopupContainer={() => userProfileRef.current!}
                dropdownRender={() => (
                  <>
                    <Card
                      title={`${currentUser.first_name} ${currentUser.last_name}`}
                      label={t('common.view_your_profile')}
                      icon={
                        <Avatar
                          src={
                            !!currentUser?.image && (
                              <Image
                                src={currentUser?.image}
                                width={56}
                                height={56}
                                objectFit="cover"
                                alt="avatar"
                              />
                            )
                          }
                          size={56}
                          className="!bg-gray-300"
                        >
                          {!currentUser?.image && (
                            <span className=" !text-md text-black">
                              {getUserInitials(currentUser)}
                            </span>
                          )}
                        </Avatar>
                      }
                      className="w-[360px] !p-4"
                      onClick={handleProfileClick}
                    />

                    <Menu className="!mt-5">
                      {profileLinks.map(({ key, url, name, icon }) => (
                        <Menu.item
                          key={key}
                          icon={icon}
                          className="[&_a]:!transition-none"
                        >
                          <Link href={url}>{t(name)}</Link>
                        </Menu.item>
                      ))}
                      {/* {isBrand && (
                        <Menu.item icon={<AddCircle size="16" />}>
                          {t('common.add_product')}
                        </Menu.item>
                      )} */}
                      {/* <Menu.item icon={<Receipt1 size="16" />}>
                        {t('common.orders_history')}
                      </Menu.item>
                      {isBrand && (
                        <Menu.item icon={<MessageFavorite size="16" />}>
                          {t('common.feedbacks')}
                        </Menu.item>
                      )}
                      <Menu.item icon={<PasswordCheck size="16" />}>
                        {t('common.vouchers')}
                      </Menu.item>
                      <Menu.item icon={<Setting2 size="16" />}>
                        {t('common.settings')}
                      </Menu.item> */}
                      <Divider className="!m-0 !mt-3" />
                      <Menu.item
                        onClick={handleLogout}
                        icon={<LogoutCurve size="16" />}
                      >
                        {t('common.logout')}
                      </Menu.item>
                    </Menu>
                  </>
                )}
                onOpenChange={setUserDropdownVisible}
              >
                <div className="cursor-pointer px-5 py-4">
                  <div className="relative h-[36px] w-[36px] overflow-hidden rounded-full bg-gray-300">
                    <Avatar
                      src={
                        !!currentUser?.image && (
                          <Image
                            src={currentUser?.image}
                            width={36}
                            height={36}
                            objectFit="cover"
                            alt="avatar"
                          />
                        )
                      }
                      size={36}
                      className="!bg-gray-300"
                    >
                      {!currentUser?.image && (
                        <span className=" !text-base text-black">
                          {getUserInitials(currentUser)}
                        </span>
                      )}
                    </Avatar>
                  </div>
                </div>
              </Dropdown>
            </div>
          )}
        </div>
      </header>
    </>
  )
}

export default Header
