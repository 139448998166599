'use client'

import useUpdateEffect from '@/hooks/useUpdateEffect'
import { animated, useTransition } from '@react-spring/web'
import classNames from 'classnames'
import React, { PropsWithChildren, useRef } from 'react'
import { useOnClickOutside, useScrollLock } from 'usehooks-ts'

type MobilePopupWrapperProps = {
  isOpen?: boolean
  className?: string
}

export const MobilePopupWrapper = ({
  children,
  isOpen,
  className
}: PropsWithChildren<MobilePopupWrapperProps>) => {
  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const { lock, unlock } = useScrollLock({
    autoLock: false
  })

  useUpdateEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        lock()
      }, 0)
    } else {
      unlock()
    }
  }, [isOpen])

  const enhancedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isOpen } as any)
    }
    return child
  })

  return transitions(
    (style, visible) =>
      visible && (
        <animated.div
          style={style}
          className={classNames(
            'bg-black bg-opacity-60 fixed bottom-[72.5px] w-full h-[calc(100vh-72.5px)] z-[81] left-0',
            className,
            {}
          )}
        >
          {enhancedChildren}
        </animated.div>
      )
  )
}

type MobilePopupContentProps = {
  onOutsideClick: (e: any) => void
  className?: string
  triggerRef?: HTMLElement | null
  isOpen?: boolean
}

export const MobilePopupContent = ({
  children,
  className,
  onOutsideClick,
  triggerRef,
  isOpen
}: PropsWithChildren<MobilePopupContentProps>) => {
  const ref = useRef(null)

  useOnClickOutside(ref, (e) => {
    if (triggerRef && !triggerRef.contains(e.target as Node)) {
      onOutsideClick?.(e)
    } else if (!triggerRef) {
      onOutsideClick?.(e)
    }
  })

  const transitions = useTransition(isOpen, {
    from: { transform: 'translateY(80%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(80%)' }
  })

  return (
    <div className="w-full h-full flex items-end overflow-hidden">
      {transitions(
        (style, visible) =>
          visible && (
            <animated.div
              ref={ref}
              style={style}
              className={classNames(
                'max-h-[80%] overflow-y-auto w-full rounded-t-xl bg-white',
                className
              )}
            >
              {children}
            </animated.div>
          )
      )}
    </div>
  )
}
