'use client'

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState
} from 'react'

interface PageOverlayContextType {
  isOverlayVisible: boolean
  toggleOverlay: () => void
  setIsOverlayVisible: (value: boolean) => void
}

const PageOverlayContext = createContext<PageOverlayContextType | undefined>(
  undefined
)

export const usePageOverlayContext = () => {
  const context = useContext(PageOverlayContext)
  if (!context) {
    throw new Error(
      'usePageOverlayContext must be used within a PageOverlayProvider'
    )
  }
  return context
}

export const PageOverlayProvider = ({ children }: PropsWithChildren) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible)
  }

  const value: PageOverlayContextType = {
    isOverlayVisible,
    toggleOverlay,
    setIsOverlayVisible
  }

  return (
    <PageOverlayContext.Provider value={value}>
      {children}
    </PageOverlayContext.Provider>
  )
}
