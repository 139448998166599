import Button from '@/components/Button'
import Status, { ProcessStatus } from '@/components/Status'
import useTranslate from '@/hooks/useTranslate'
import classNames from 'classnames'
import { PropsWithChildren, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

export type ModalAlertProps = PropsWithChildren<{
  className?: string
  title?: string
  description?: ReactNode
  status: ProcessStatus
  showContact?: boolean
}>

const ModalAlert = ({
  className,
  title,
  description,
  status,
  showContact
}: ModalAlertProps) => {
  const classes = useStyles()
  const t = useTranslate()

  return (
    <div className={classNames('', className)}>
      <div className="mb-4 flex justify-center">
        <Status status={status} />
      </div>
      <h4 className=" mb-2 text-center text-base font-medium text-black">
        {title}
      </h4>
      {description && (
        <p className="m-0 text-center text-sm text-black">{description}</p>
      )}
      {showContact && (
        <div className="flex justify-center pt-4">
          <Button size="small" type="primary">
            კონტაქტი
          </Button>
        </div>
      )}
    </div>
  )
}

const useStyles = createUseStyles({})

export default ModalAlert
