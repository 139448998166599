import classNames from 'classnames'
import { createUseStyles } from 'react-jss'

export type HeaderProps = {
  className?: string
  keyword: string
  results: string[]
  onClick?: (keyword: string) => void
}

const SearchSuggestions = ({
  className,
  keyword,
  results,
  onClick
}: HeaderProps) => {
  const classes = useStyles()

  const highlightKeyword = (text: string) => {
    const regex = new RegExp(`(${keyword})`, 'gi')
    const parts = text.split(regex)

    return parts.map((part, index) =>
      regex.test(part) ? (
        <strong className="text-black" key={index}>
          {part}
        </strong>
      ) : (
        part
      )
    )
  }

  return (
    <div className={classNames('w-full font-firago', className)}>
      <ul className="flex w-full flex-col gap-3">
        {results.map((result, index) => (
          <li
            key={index}
            className="cursor-pointer text-sm text-gray-400 transition-all hover:text-black"
            onClick={() => onClick?.(result)}
          >
            {highlightKeyword(result)}
          </li>
        ))}
      </ul>
    </div>
  )
}

const useStyles = createUseStyles({})

export default SearchSuggestions
