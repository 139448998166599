'use client'

import classNames from 'classnames'
import NextImage, { ImageProps } from 'next/image'

const Image = ({
  objectFit,
  width,
  height,
  layout,
  ...props
}: ImageProps & { objectFit?: any; layout?: string }) => {
  return (
    <NextImage
      style={{ objectFit }}
      className={classNames({
        '!w-full !h-auto !block': layout === 'responsive'
      })}
      width={width}
      height={height}
      fill={layout === 'fill'}
      loader={({ src }) => src}
      loading="lazy"
      {...props}
    />
  )
}

export default Image
