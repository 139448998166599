'use client'

import { SachemoLogoGeo } from '@/components/SVG'
import useCurrentUser from '@/hooks/useCurrentUser'
import { useCurrentUserRevalidate } from '@/hooks/useCurrentUserRevalidate'
import { useSignIn } from '@/hooks/useSignIn'
import { Avatar, Dropdown, Layout, Menu, MenuProps, theme } from 'antd'
import {
  Box,
  Category,
  DiscountShape,
  Home,
  Setting,
  Shop,
  User,
  Wallet2
} from 'iconsax-react'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'

const { Header, Content, Sider } = Layout

export const DashboardLayout = ({ children }: any) => {
  const router = useRouter()
  const { currentUser, setCurrentUser } = useCurrentUser()
  const {
    token: { colorBgContainer }
  } = theme.useToken()
  const menuItems = [
    {
      key: 'dashboard',
      icon: <Home size={18} />,
      label: `მთავარი`
    },
    {
      key: 'brands',
      icon: <Shop size={18} />,
      label: `ბრენდები`
    },
    {
      key: 'users',
      icon: <User size={18} />,
      label: `მომხმარებლები`
    },
    {
      key: 'products',
      icon: <Wallet2 size={18} />,
      label: `პროდუქტები`
    },
    {
      key: 'orders',
      icon: <Box size={18} />,
      label: `შეკვეთები`
    },
    {
      key: 'categories',
      icon: <Category size={18} />,
      label: `კატეგორიები`
    },
    {
      key: 'promocodes',
      icon: <DiscountShape size={18} />,
      label: `პრომო კოდები`
    },
    {
      key: 'settings',
      icon: <Setting size={18} />,
      label: `პარამეტრები`
    }
  ]
  const pathname = usePathname()

  const onSelect = ({ key }: any) =>
    router.replace(`/dashboard/${key === 'dashboard' ? '' : key}`)

  const activeKey = (): string => {
    const pathnameParts = pathname.split('/')

    return pathnameParts[2] ?? 'dashboard'
  }

  const { logout } = useSignIn()

  useCurrentUserRevalidate(true)

  const userItems: MenuProps['items'] = [
    {
      label: (
        <a target="_blank" href="/">
          საიტზე გადასვლა
        </a>
      ),
      key: 'website'
    },
    {
      label: (
        <a href="/profile" target="_blank">
          პროფილი
        </a>
      ),
      key: 'profile'
    },
    {
      type: 'divider'
    },
    {
      label: 'გასვლა',
      key: 'logout',
      onClick: logout
    }
  ]

  return (
    <Layout className="h-screen">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken)
        }}
        width={220}
        className="px-1 border-r border-gray-200"
        theme="light"
      >
        <div className="!mb-7 my-5 [&_svg]:w-[150px] [&_svg]:h-[25px]">
          <Link className="block w-full flex justify-center" href="/dashboard">
            <SachemoLogoGeo />
          </Link>
        </div>
        <Menu
          theme="light"
          onClick={onSelect}
          activeKey={activeKey()}
          defaultSelectedKeys={[activeKey()]}
          items={menuItems}
          className="!border-0 [&_.ant-menu-item]:!flex [&_.ant-menu-item]:!items-center"
        />
      </Sider>
      <Layout>
        <Header
          className="!py-0 !px-4 flex justify-end items-center"
          style={{ background: colorBgContainer }}
        >
          {currentUser && (
            <Dropdown
              className="!leading-[1]"
              menu={{ items: userItems }}
              trigger={['click']}
            >
              <div className="cursor-pointer hover:bg-[#fafafa] transition-all duration-500 rounded-md py-1 px-2">
                <Avatar
                  style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}
                >
                  {currentUser?.first_name[0]}
                </Avatar>
                <span className="pl-2">
                  {currentUser?.first_name} {currentUser?.last_name}
                </span>
              </div>
            </Dropdown>
          )}
        </Header>
        <Content className="overflow-y-auto mx-4 my-4 p-4 bg-white rounded-md">
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}
