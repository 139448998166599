'use client'

import { getMessages } from '@/locales'
import { useLocale } from 'next-intl'
import { PropsWithChildren } from 'react'
import { IntlProvider } from 'react-intl'

export const ReactIntlProvider = ({
  children,
  hasLocale = true
}: PropsWithChildren<{ hasLocale?: boolean }>) => {
  const locale = hasLocale ? useLocale() : null
  const defaultLocale = 'ka'
  const messages = getMessages(locale ?? defaultLocale!)

  return (
    <IntlProvider
      onError={() => null}
      messages={messages}
      locale={locale!}
      defaultLocale={defaultLocale}
    >
      {children}
    </IntlProvider>
  )
}
