import { Form, FormItemProps } from 'antd'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'

const FormItem = ({ className, ...props }: FormItemProps) => {
  const classes = useStyles()

  return (
    <Form.Item
      className={classNames(
        className,
        '[&>.ant-row>.ant-col>label]:pl-3',
        classes.formItem
      )}
      {...props}
      required={false}
    />
  )
}

const useStyles = createUseStyles({
  formItem: {
    '&.ant-form-item-has-error': {
      '& .ant-form-item-label label': {
        color: '#FF0000'
      }
    },
    '& .ant-form-item-explain': {
      marginBottom: 10,
      marginTop: 5,
      fontSize: 13
    }
  }
})

export default FormItem
