import {
  LightboxContextType,
  useLightboxContext
} from '@/context/LightboxContext'

const useLightbox = (): LightboxContextType => {
  const { currentIndex, setCurrentIndex, data, setData } = useLightboxContext()

  return { currentIndex, setCurrentIndex, data, setData }
}

export default useLightbox
