import { CartContextType, useCartContext } from '@/context/CartContext'

const useCart = (): CartContextType => {
  const {
    cart,
    visible,
    cartLineUpdating,
    cartLineRemoving,
    cartClearing,
    setCart,
    setCartVisible,
    updateLineItem,
    removeLineItem,
    clearCart,
    addToCart,
    addingToCart
  } = useCartContext()

  return {
    visible,
    cartLineUpdating,
    cartLineRemoving,
    cartClearing,
    cart,
    addToCart,
    addingToCart,
    setCart,
    setCartVisible,
    updateLineItem,
    removeLineItem,
    clearCart
  }
}

export default useCart
