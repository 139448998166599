import HeaderSearch from '@/components/Header/HeaderSearch'
import IconButton from '@/components/IconButton'
import Image from '@/components/Image'
import Link, { LinkProps } from '@/components/Link'
import useCurrentUser from '@/hooks/useCurrentUser'
import { useMobileHeader } from '@/hooks/useMobileHeader'
import useModal from '@/hooks/useModal'
import { useSignIn } from '@/hooks/useSignIn'
import useTranslate from '@/hooks/useTranslate'
import { getUserInitials } from '@/utils/utils'
import { Avatar } from 'antd'
import classNames from 'classnames'
import {
  ArrowRight2,
  Bag2,
  CloseCircle,
  HambergerMenu,
  Heart,
  Home,
  LogoutCurve,
  NotificationBing,
  PasswordCheck,
  Receipt1,
  SearchNormal1,
  Setting2,
  User
} from 'iconsax-react'
import NextLink from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { createUseStyles } from 'react-jss'
import { useOnClickOutside } from 'usehooks-ts'
import Badge from '../Badge'
import Card from '../Card'
import { MobilePopupContent, MobilePopupWrapper } from '../MobilePopup'

export type MobileNavigationProps = PropsWithChildren<{
  className?: string
  isMenuOpen?: boolean
  isSearchOpen?: boolean
  keyword?: string
  suggestions?: string[]
  popularSearches?: string[]
  latestSearches?: string[]
  isLoggedIn?: boolean
  onSearch?: (value?: string) => void
  onClick?: () => void
  onMenuClose?: () => void
  onMenuToggle?: () => void
  onUserToggle?: () => void
  onSearchToggle?: () => void
  onCloseAll?: () => void
  forceVisible?: boolean
  isUserOpen?: boolean
}>

const MobileNavigation = ({
  isMenuOpen,
  isUserOpen,
  isSearchOpen,
  onMenuToggle,
  onUserToggle,
  onSearchToggle,
  className,
  suggestions,
  latestSearches,
  popularSearches,
  keyword,
  forceVisible,
  onSearch,
  onCloseAll
}: MobileNavigationProps) => {
  const classes = useStyles()
  const router = useRouter()
  const avatarRef = useRef(null)
  const menuRef = useRef(null)
  const searchRef = useRef<any>(null)
  const searchWrapperRef = useRef(null)
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>()
  const onSearchChange = (value: string) => setSearchKeyword(value)
  const {
    isMobileMenuOpen,
    setIsMobileMenuOpen,
    isMobileUserOpen,
    setIsMobileUserOpen,
    setIsMobileSearchOpen,
    isMobileSearchOpen
  } = useMobileHeader()
  const { currentUser } = useCurrentUser()
  const { logout } = useSignIn()
  const t = useTranslate()
  const pathname = usePathname()
  const { open } = useModal()

  useEffect(() => setSearchKeyword(keyword), [keyword])

  useOnClickOutside(
    searchWrapperRef,
    useCallback(
      (e: any) => {
        if (
          isMobileSearchOpen &&
          !searchRef.current?.contains(e.target as Node)
        ) {
          setIsMobileSearchOpen?.(false)
        }
      },
      [isMobileSearchOpen]
    )
  )

  const onSuggestion = (keyword: string) => {
    onSearchChange(keyword)
    onSearch?.(keyword)
    setIsMobileSearchOpen?.(false)
  }

  const handleNavigationClick = () => {
    //
  }

  const handleSearchClick = useCallback(() => {
    setIsMobileSearchOpen?.(!isMobileSearchOpen)
  }, [setIsMobileSearchOpen, isMobileSearchOpen])

  const handleMenuClick = useCallback(() => {
    setIsMobileMenuOpen?.(!isMobileMenuOpen)
  }, [setIsMobileMenuOpen, isMobileMenuOpen])

  const handleMenuNavigation = () => {
    onMenuToggle?.()
  }

  const handleUserNavigation = () => {
    setIsMobileUserOpen?.(false)
  }

  const handleUserClick = useCallback(
    (event: any) => {
      setIsMobileUserOpen?.(!isMobileUserOpen)
    },
    [setIsMobileUserOpen, isMobileUserOpen]
  )

  const handleLogout = () => {
    handleUserNavigation()
    logout()
  }

  const handleProfileClick = () => {
    handleUserNavigation()
    router.replace('/profile')
  }

  const NavLink = ({
    href,
    children,
    className,
    arrow = true
  }: LinkProps & { arrow?: boolean }) => {
    return (
      <Link
        href={href}
        onClick={isMenuOpen ? handleMenuNavigation : handleUserNavigation}
        className={classNames(
          'flex items-center justify-between py-2',
          className
        )}
      >
        {children} {arrow && <ArrowRight2 size={16} />}
      </Link>
    )
  }

  const handleMobileUserOutsideClick = useCallback(() => {
    if (isMobileUserOpen) {
      setIsMobileUserOpen?.(false)
    }
  }, [isMobileUserOpen, setIsMobileUserOpen])

  const handleMobileMenuOutsideClick = useCallback(() => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen?.(false)
    }
  }, [isMobileMenuOpen, setIsMobileMenuOpen])

  return (
    <>
      <nav
        className={classNames(
          'fixed bottom-0 left-0 z-[70] w-full font-firago lg:hidden',
          {
            '!block': forceVisible
          },
          className
        )}
      >
        <div className="flex items-center justify-between overflow-hidden border-t border-gray-200 bg-white">
          <div className="flex h-full items-center p-3.5">
            <NextLink href="/">
              <IconButton
                icon={
                  <Home
                    size={24}
                    variant={pathname === '/' ? 'Bold' : 'Linear'}
                  />
                }
                type="ghost"
                className="hover:bg-gray-200"
                size="medium"
                onClick={handleNavigationClick}
              />
            </NextLink>
          </div>
          <div className="flex h-full items-center px-3.5">
            <IconButton
              icon={<SearchNormal1 size={24} variant="Linear" />}
              type="ghost"
              className="hover:bg-gray-200"
              size="medium"
              onClick={handleSearchClick}
              ref={searchRef}
            />
          </div>
          <div className="flex h-full items-center px-3.5">
            <NextLink href="/">
              <IconButton
                icon={<Bag2 size={24} variant="Linear" />}
                className="hover:bg-gray-200"
                size="medium"
                type="secondary-dark"
                active
                onClick={handleNavigationClick}
              />
            </NextLink>
          </div>
          <div className="flex h-full items-center px-3.5">
            {!!currentUser && (
              <div>
                <Avatar
                  ref={avatarRef}
                  onClick={handleUserClick}
                  src={
                    !!currentUser?.image && (
                      <Image
                        src={currentUser?.image}
                        width={36}
                        height={36}
                        objectFit="cover"
                        alt="avatar"
                      />
                    )
                  }
                  size={36}
                  className="!bg-gray-300"
                >
                  {!currentUser?.image && (
                    <span className=" !text-base text-black">
                      {getUserInitials(currentUser)}
                    </span>
                  )}
                </Avatar>
              </div>
            )}

            {!currentUser && (
              <IconButton
                icon={<User size={24} variant="Linear" />}
                type="ghost"
                className="hover:bg-gray-200"
                size="medium"
                onClick={() => open('AUTH')}
              />
            )}
          </div>
          <div className="flex h-full items-center px-3.5">
            <IconButton
              icon={
                isMobileMenuOpen ? (
                  <CloseCircle size={24} variant="Bold" />
                ) : (
                  <HambergerMenu size={24} />
                )
              }
              type="ghost"
              className="hover:bg-gray-200"
              size="medium"
              onClick={handleMenuClick}
              ref={menuRef}
            />
          </div>
        </div>
      </nav>
      <MobilePopupWrapper isOpen={isMobileMenuOpen}>
        <MobilePopupContent
          className="px-5 py-2.5"
          triggerRef={menuRef.current}
          onOutsideClick={handleMobileMenuOutsideClick}
        >
          <ul>
            <li>
              <NavLink href="/">საჩემოს შესახებ</NavLink>
            </li>
            <li>
              <NavLink href="/">წესები და პირობები</NavLink>
            </li>
            <li>
              <NavLink href="/">პოლიტიკა</NavLink>
            </li>
            <li>
              <NavLink href="/">კონტაქტი</NavLink>
            </li>
            <li>
              <NavLink href="/">ხ.დ.კ.</NavLink>
            </li>
          </ul>
          <hr className="my-2" />
          <ul className=" font-medium uppercase">
            <li>
              <NavLink href="/">ტანსაცმელი</NavLink>
            </li>
            <li>
              <NavLink href="/">აქსესუარი</NavLink>
            </li>
            <li>
              <NavLink href="/">ბრენდი</NavLink>
            </li>
            <li>
              <NavLink href="/">ფასდაკლება</NavLink>
            </li>
            <li>
              <NavLink href="/">ბლოგი</NavLink>
            </li>
          </ul>
        </MobilePopupContent>
      </MobilePopupWrapper>

      <MobilePopupWrapper isOpen={isMobileUserOpen}>
        <MobilePopupContent
          className="px-5 py-2.5"
          onOutsideClick={handleMobileUserOutsideClick}
          triggerRef={avatarRef.current}
        >
          {currentUser && (
            <div>
              <ul className="">
                <li>
                  <button
                    onClick={handleLogout}
                    className={classNames(
                      'flex items-center justify-start border-0 py-2 text-sm shadow-none outline-none',
                      className
                    )}
                  >
                    <LogoutCurve size={17} className="mr-3" /> გასვლა
                  </button>
                </li>
              </ul>
              <hr className="my-2" />
              <ul className="">
                <li>
                  <NavLink className="!justify-start" arrow={false} href="/">
                    <NotificationBing size={17} className="mr-3" />{' '}
                    შეტყობინებები
                    <Badge type="danger" shape="circle" className="ml-3">
                      1
                    </Badge>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="!justify-start" arrow={false} href="/">
                    <Receipt1 size={17} className="mr-3" /> შეკვეთების ისტორია
                  </NavLink>
                </li>
                <li>
                  <NavLink className="!justify-start" arrow={false} href="/">
                    <Heart size={17} className="mr-3" /> ფავორიტები
                  </NavLink>
                </li>
                <li>
                  <NavLink className="!justify-start" arrow={false} href="/">
                    <Bag2 size={17} className="mr-3" /> ჩემი კალათა
                  </NavLink>
                </li>
                <li>
                  <NavLink className="!justify-start" arrow={false} href="/">
                    <PasswordCheck size={17} className="mr-3" /> ვაუჩერები
                  </NavLink>
                </li>
                <li>
                  <NavLink className="!justify-start" arrow={false} href="/">
                    <Setting2 size={17} className="mr-3" /> პარამეტრები
                  </NavLink>
                </li>
              </ul>

              <Card
                className={classNames('my-4 !bg-white !p-4', {})}
                title={`${currentUser.first_name} ${currentUser.last_name}`}
                label={t('common.view_your_profile')}
                imageClassName="!w-11 !h-11"
                icon={
                  <Avatar
                    src={
                      !!currentUser?.image && (
                        <Image
                          src={currentUser?.image}
                          width={44}
                          height={44}
                          objectFit="cover"
                          alt="avatar"
                        />
                      )
                    }
                    size={44}
                    className="!bg-gray-300"
                  >
                    {!currentUser?.image && (
                      <span className=" !text-base text-black">
                        {getUserInitials(currentUser)}
                      </span>
                    )}
                  </Avatar>
                }
                onClick={() => handleProfileClick()}
              />
            </div>
          )}
        </MobilePopupContent>
      </MobilePopupWrapper>

      <MobilePopupWrapper className="!z-[79]" isOpen={isMobileSearchOpen}>
        <div className="pt-[170px] px-4" ref={searchWrapperRef}>
          <HeaderSearch
            value={searchKeyword}
            suggestions={suggestions}
            latestSearches={latestSearches}
            popularSearches={popularSearches}
            onSearchChange={onSearchChange}
            onSearch={() => onSearch?.(searchKeyword)}
            onClear={() => setSearchKeyword('')}
            onSuggestion={onSuggestion}
            shouldFocus
          />
        </div>
      </MobilePopupWrapper>
    </>
  )
}

const useStyles = createUseStyles({})

export default MobileNavigation
