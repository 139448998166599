import Button from '@/components/Button'
import FormItem from '@/components/FormItem'
import Input from '@/components/Input'
import PasswordStrengthChecker from '@/components/PasswordStrengthChecker'
import { useBreakpoint } from '@/hooks/useBreakpoints'
import useFormValidation from '@/hooks/useFormValidation'
import usePasswordStrengthChecker from '@/hooks/usePasswordStrengthChecker'
import useTranslate from '@/hooks/useTranslate'
import { fakerEN, fakerKA_GE } from '@faker-js/faker'
import { Form } from 'antd'
import classNames from 'classnames'
import { useLocale } from 'next-intl'
import { PropsWithChildren, useMemo } from 'react'

export type RegisterFormProps = PropsWithChildren<{
  className?: string
  onSubmit?: (data: any) => void
  isLoading?: boolean
  serverValidationErrors?: any
}>

const RegisterForm = ({
  className,
  isLoading,
  serverValidationErrors,
  onSubmit
}: RegisterFormProps) => {
  const t = useTranslate()
  const locale = useLocale()
  const { isMobile } = useBreakpoint()
  const randomName = useMemo(
    () =>
      locale === 'ka'
        ? fakerKA_GE.person.firstName()
        : fakerEN.person.firstName(),
    [locale]
  )
  const randomLastName = useMemo(
    () =>
      locale === 'ka'
        ? fakerKA_GE.person.lastName()
        : fakerEN.person.lastName(),
    [locale]
  )

  const {
    rules: { required, isStrong, isEmail, isMobileNumber },
    getServerError
  } = useFormValidation({ serverValidationErrors })

  console.log({ serverValidationErrors })

  const [form] = Form.useForm()
  const password = Form.useWatch('password', { form, preserve: true })
  const { scoreKey } = usePasswordStrengthChecker(password ?? '')

  return (
    <div className={classNames('', className)}>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <FormItem
          name="email"
          label={t('common.email')}
          rules={[required(), isEmail()]}
          validateStatus={getServerError('email') ? 'error' : undefined}
          help={getServerError('email')}
        >
          <Input type="email" allowClear placeholder="info@sachemo.ge" />
        </FormItem>

        <FormItem
          name="first_name"
          label={t('common.first_name')}
          rules={[required()]}
        >
          <Input name="first_name" allowClear placeholder={randomName} />
        </FormItem>

        <FormItem
          name="last_name"
          label={t('common.last_name')}
          rules={[required()]}
        >
          <Input name="last_name" allowClear placeholder={randomLastName} />
        </FormItem>

        <FormItem
          name="phone"
          label={t('common.mobile_number')}
          rules={[required(), isMobileNumber()]}
        >
          <Input name="phone" allowClear />
        </FormItem>

        <FormItem
          name="password"
          label={t('common.password')}
          rules={[required(), isStrong(scoreKey)]}
        >
          <Input.Password
            name="password"
            type="password"
            placeholder="*******"
            allowClear
          />
        </FormItem>

        {password && (
          <PasswordStrengthChecker minimal={isMobile} password={password} />
        )}

        <Button
          block
          htmlType="submit"
          type="primary"
          className="mt-6"
          disabled={
            !form.isFieldsTouched([
              'email',
              'first_name',
              'last_name',
              'password'
            ])
          }
          loading={isLoading}
        >
          {t('common.register')}
        </Button>
      </Form>
    </div>
  )
}

export default RegisterForm
