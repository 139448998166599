'use client'

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useRef
} from 'react'

const HeaderRefContext = createContext<
  React.RefObject<HTMLElement> | undefined
>(undefined)

export const useHeaderRefContext = () => {
  const context = useContext(HeaderRefContext)
  if (!context) {
    throw new Error(
      'useHeaderRefContext must be used within a HeaderRefProvider'
    )
  }
  return context
}

export const HeaderRefProvider = ({ children }: PropsWithChildren) => {
  const headerRef = useRef<HTMLElement | null>(null)

  return (
    <HeaderRefContext.Provider value={headerRef}>
      {children}
    </HeaderRefContext.Provider>
  )
}
