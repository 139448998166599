import { AlertProps as AntAlertProps, Alert as AntAlert } from 'antd'
import classNames from 'classnames'
import Status, { ProcessStatus } from '@/components/Status'
import { CloseCircle } from 'iconsax-react'
import { createUseStyles } from 'react-jss'

export type AlertProps = AntAlertProps & { size?: 'small' | 'default' }

const Alert = ({
  className,
  type = 'info',
  showIcon = true,
  size = 'default',
  closable = false,
  ...props
}: AlertProps) => {
  const classes = useStyles()
  const icons = {
    success: <Status size={size} status={ProcessStatus.SUCCESS} />,
    info: <Status size={size} status={ProcessStatus.INFO} />,
    warning: <Status size={size} status={ProcessStatus.WARNING} />,
    error: <Status size={size} status={ProcessStatus.ERROR} />
  }

  return (
    <AntAlert
      className={classNames(
        'relative items-center font-firago',
        className,
        classes.alert
      )}
      closable={closable}
      showIcon={showIcon}
      closeIcon={<CloseCircle size={18} />}
      icon={icons[type]}
      type={type}
      {...props}
    />
  )
}

const useStyles = createUseStyles({
  alert: {
    '& .ant-alert-close-icon': {
      position: 'absolute',
      top: 12,
      right: 12
    },
    '& .ant-alert-message': {
      'font-feature-settings': "'case' on",
      fontSize: '14px',
      fontWeight: 600,
      marginBottom: '4px',
      lineHeight: 1
    }
  }
})

export default Alert
