import Dropdown from '@/components/Dropdown'
import IconButton from '@/components/IconButton'
import useTranslate from '@/hooks/useTranslate'
import { animated, useTransition } from '@react-spring/web'
import classNames from 'classnames'
import { CloseCircle, SearchNormal1 } from 'iconsax-react'
import {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'
import uniqid from 'uniqid'
import SearchSuggestions from './SearchSuggestions'

export type HeaderProps = {
  className?: string
  isLoggedIn?: boolean
  isBrand?: boolean
  value?: string
  onSearchChange?: (keyword: string) => void
  onSuggestion?: (keyword: string) => void
  onSearch?: () => void
  onClear?: () => void
  suggestions?: string[]
  latestSearches?: string[]
  popularSearches?: string[]
  shouldFocus?: boolean
}

const HeaderSearch = ({
  className,
  value,
  onClear,
  onSearchChange,
  onSearch,
  onSuggestion,
  suggestions,
  latestSearches,
  popularSearches,
  shouldFocus
}: HeaderProps) => {
  const classes = useStyles()
  const [inputTouched, setInputTouched] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [doNotCloseModal, setDoNotCloseModal] = useState(false)
  const t = useTranslate()
  const formRef = useRef(null)
  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e: any) => {
      e.preventDefault()
      e?.stopPropagation()
      onSearch?.()
      setIsDropdownVisible(false)
    },
    [onSearch]
  )

  const handleSuggestion = (keyword: string) => {
    onSuggestion?.(keyword)
    setInputTouched(false)
    setIsDropdownVisible(false)
  }

  const handleClear = (e: any) => {
    e?.stopPropagation()
    onClear?.()
    setDoNotCloseModal(true)
    inputRef.current?.focus()
    setInputTouched(false)
  }

  useEffect(() => {
    if (isMobile) {
      setIsDropdownVisible(true)
    }
  }, [])

  useEffect(() => {
    if (shouldFocus && inputRef.current) {
      inputRef.current?.focus()
    }
  }, [shouldFocus])

  const handleInputChange = (e: any) => {
    onSearchChange?.(e.target.value)
    setInputTouched(true)

    if (!isDropdownVisible) {
      setIsDropdownVisible(true)
    }
  }

  const handleRemoveSuggestion = (e: any, suggestion: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const transitions = useTransition(isDropdownVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className={classNames(
          'lg!max-w-[50vw] relative flex items-center lg:w-[700px] xl:w-[900] 2xl:xl:w-[1000]',
          className
        )}
      >
        <Dropdown
          className="!w-full"
          rootClassName="rounded-xl border-0 !top-full !mt-5 !w-full"
          trigger={['click']}
          open={isDropdownVisible}
          onOpenChange={setIsDropdownVisible}
          getPopupContainer={() => formRef.current!}
          dropdownRender={() => (
            <div>
              {!inputTouched && (
                <>
                  <div className="font-firago">
                    <span className=" mb-3 block text-sm font-medium uppercase">
                      {t('common.recently_searched')}
                    </span>
                    {latestSearches && !!latestSearches.length && (
                      <ul className="flex h-[83px] flex-wrap gap-2.5 overflow-hidden sm:h-auto">
                        {latestSearches.map((suggestion) => (
                          <li className="relative" key={uniqid()}>
                            <button
                              onClick={() => handleSuggestion(suggestion)}
                              className="inline-flex items-end rounded-lg bg-gray-200 px-2 py-1.5 pr-8 text-sm transition-all duration-300 hover:bg-gray-300 active:bg-black active:text-white"
                            >
                              <span>{suggestion}</span>
                            </button>
                            <CloseCircle
                              onClick={(e) =>
                                handleRemoveSuggestion(e, suggestion)
                              }
                              size="16"
                              className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer text-gray-400 transition-colors hover:text-red"
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                    <span className=" mb-3 mt-5 block text-sm font-medium uppercase">
                      {t('common.popular')}
                    </span>
                    {popularSearches && !!popularSearches.length && (
                      <ul className="flex h-[83px] flex-wrap gap-2.5 overflow-hidden sm:h-auto">
                        {popularSearches.map((suggestion) => (
                          <li key={uniqid()}>
                            <button
                              onClick={() => handleSuggestion(suggestion)}
                              className="rounded-lg bg-gray-200 px-2 py-1.5 text-sm transition-all duration-300 hover:bg-gray-300 active:bg-black active:text-white"
                            >
                              {suggestion}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </>
              )}

              {inputTouched && (
                <SearchSuggestions
                  keyword={value ?? ''}
                  results={suggestions ?? []}
                  onClick={handleSuggestion}
                />
              )}
            </div>
          )}
        >
          <div className={classNames('relative overflow-hidden rounded-md')}>
            <input
              className="w-full bg-gray-200 px-4 py-3 font-firago text-sm text-black outline-none placeholder:text-black focus:placeholder:text-transparent"
              placeholder={t('common.search')}
              value={value ?? ''}
              onChange={handleInputChange}
              ref={inputRef}
              onClick={(e: any) => isDropdownVisible && e?.stopPropagation()}
            />

            <IconButton
              className={classNames(
                '!absolute right-0 top-1/2 hidden -translate-y-1/2 !py-0',
                {
                  '!block': !value
                }
              )}
              type="ghost"
              icon={<SearchNormal1 size={20} className="text-gray-400" />}
              htmlType="submit"
            />

            <IconButton
              className={classNames(
                '!absolute right-0 top-1/2 hidden -translate-y-1/2',
                {
                  '!block': !!value
                }
              )}
              type="ghost"
              icon={<CloseCircle size={20} className="text-black" />}
              onClick={handleClear}
            />
          </div>
        </Dropdown>
      </form>
      {transitions(
        (style, visible) =>
          visible && (
            <animated.div
              style={style}
              className={classNames(
                'bg-black bg-opacity-60 fixed left-0 top-[65px] w-full h-[calc(100vh-65px)] z-[81] max-lg:!hidden'
              )}
            />
          )
      )}
    </>
  )
}

const useStyles = createUseStyles({})

export default HeaderSearch
