import createAxiosInstance from '@/services/api'
import useSWRMutation from 'swr/mutation'

type Events<T> = {
  onFinish?: (data: T) => void
}

export const usePost = <T>(url: string, options?: { multipart?: boolean }) => {
  const axios = createAxiosInstance(undefined, options)
  const poster = async (url: string, body: any) => {
    const res = await axios.post(url, body?.arg)
    return res.data
  }
  const { data, error, isMutating, trigger } = useSWRMutation<T>(url, poster)

  return {
    data,
    isLoading: isMutating,
    isError: !!error,
    error,
    trigger
  }
}
