import Button from '@/components/Button'
import FormItem from '@/components/FormItem'
import Input from '@/components/Input'
import useFormValidation from '@/hooks/useFormValidation'
import useTranslate from '@/hooks/useTranslate'
import { Form } from 'antd'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { createUseStyles } from 'react-jss'

export type PasswordResetRequestFormProps = PropsWithChildren<{
  className?: string
  isLoading?: boolean
  serverValidationErrors?: any
  onSubmit?: (data: any) => void
}>

const PasswordResetRequestForm = ({
  className,
  isLoading,
  serverValidationErrors,
  onSubmit
}: PasswordResetRequestFormProps) => {
  const classes = useStyles()
  const t = useTranslate()

  const {
    rules: { required },
    getServerError
  } = useFormValidation({ serverValidationErrors })

  const [form] = Form.useForm()

  const email = Form.useWatch('email', { form, preserve: true })

  return (
    <div className={classNames('', className)}>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <FormItem
          name="email"
          label={t('common.email')}
          rules={[required()]}
          validateStatus={getServerError('email') ? 'error' : undefined}
          help={getServerError('email')}
        >
          <Input
            name="email"
            type="email"
            allowClear
            placeholder="info@sachemo.ge"
          />
        </FormItem>

        <Button
          disabled={!(form.isFieldTouched('email') && !!email)}
          block
          className="mt-6"
          htmlType="submit"
          loading={isLoading}
          type="primary"
        >
          {t('common.reset_password')}
        </Button>
      </Form>
    </div>
  )
}

const useStyles = createUseStyles({})

export default PasswordResetRequestForm
