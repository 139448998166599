import Link from '@/components/Link'
import { SachemoLogoGeo } from '@/components/SVG'
import useFooterRef from '@/hooks/useFooterRef'
import classNames from 'classnames'
import { Heart } from 'iconsax-react'
import { createUseStyles } from 'react-jss'

export type FooterProps = {
  className?: string
}

const Footer = ({ className }: FooterProps) => {
  const classes = useStyles()
  const ref = useFooterRef()

  return (
    <footer
      ref={ref}
      className={classNames(
        'border-t border-gray-300 py-10 font-firago',
        className
      )}
    >
      <nav className="hidden sm:block">
        <ul className="flex items-center justify-center gap-10">
          <li>
            <Link href="/">მთავარი</Link>
          </li>
          <li>
            <Link href="/">საჩემოს შესახებ</Link>
          </li>
          <li>
            <Link href="/">წესები და პირობები</Link>
          </li>
          <li>
            <Link href="/">პოლიტიკა</Link>
          </li>
          <li>
            <Link href="/">კონტაქტი</Link>
          </li>
          <li>
            <Link href="/">ბრენდისთვის</Link>
          </li>
          <li>
            <Link href="/">ხშირად დასმული კითხვები</Link>
          </li>
        </ul>
        <ul className=" mt-8 flex items-center justify-center gap-10 font-medium uppercase">
          <li>
            <Link href="/">ყველა პროდუქცია</Link>
          </li>
          <li>
            <Link href="/">ტანსაცმელი</Link>
          </li>
          <li>
            <Link href="/">აქსესუარი</Link>
          </li>
          <li>
            <Link href="/">ჩანთა</Link>
          </li>
          <li>
            <Link href="/">სათვალე</Link>
          </li>
        </ul>
      </nav>
      <div className="mb-20 flex flex-col items-center justify-center sm:mb-0 sm:mt-[60px]">
        <Link href="/" passHref target="_blank" className="block p-6">
          <SachemoLogoGeo />
        </Link>
        <Heart size="24" color="#111722" variant="Bold" />
      </div>
    </footer>
  )
}

const useStyles = createUseStyles({})

export default Footer
