'use client'

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState
} from 'react'

interface MobileNavigationContextType {
  isMenuOpen: boolean
  toggleMenu: () => void
  closeMenu: () => void
  isUserOpen: boolean
  toggleUser: () => void
  isSearchOpen: boolean
  toggleSearch: () => void
  closeSearch: () => void
  closeUser: () => void
}

const MobileNavigationContext = createContext<
  MobileNavigationContextType | undefined
>(undefined)

export const useMobileNavigationContext = () => {
  const context = useContext(MobileNavigationContext)
  if (!context) {
    throw new Error(
      'useMobileNavigationContext must be used within a MobileNavigationProvider'
    )
  }
  return context
}

export const MobileNavigationProvider = ({ children }: PropsWithChildren) => {
  const [isUserOpen, setIsUserOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const toggleUser = () => {
    setIsUserOpen(!isUserOpen)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const closeUser = () => {
    setIsUserOpen(false)
  }

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen)
  }

  const closeSearch = () => {
    setIsSearchOpen(false)
  }

  const value: MobileNavigationContextType = {
    isMenuOpen,
    toggleMenu,
    closeMenu,
    isSearchOpen,
    toggleSearch,
    closeSearch,
    isUserOpen,
    toggleUser,
    closeUser
  }

  return (
    <MobileNavigationContext.Provider value={value}>
      {children}
    </MobileNavigationContext.Provider>
  )
}
