'use client'

import usePageOverlay from '@/hooks/usePageOverlay'
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

interface ModalContextType {
  modals: { [key: string]: boolean }
  meta: { [key: string]: any }
  open: (key: string, meta?: any, closeOther?: boolean) => void
  close: (key: string) => void
  openAlert: (meta?: any) => void
  closeAlert: () => void
  closeAll: () => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const useModalContext = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider')
  }
  return context
}

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const { setIsOverlayVisible } = usePageOverlay()
  const [modals, setModals] = useState<{ [key: string]: boolean }>({
    ALERT: false
  })
  const [meta, setMeta] = useState<{ [key: string]: any }>({
    ALERT: null
  })
  const isVisible = useMemo(
    () =>
      Object.keys(modals).reduce((previousValue: any, key: string) => {
        return modals[key] || previousValue
      }, false),
    [modals]
  )

  useEffect(() => {
    setIsOverlayVisible(isVisible)
  }, [isVisible, setIsOverlayVisible])

  const makeAllFalse = (object: any) =>
    Object.keys(object).reduce((previousValue: any, key: string) => {
      return { ...previousValue, [key]: false }
    }, {})

  const resetAllMeta = (object: any) =>
    Object.keys(object).reduce((previousValue: any, key: string) => {
      return { ...previousValue, [key]: null }
    }, {})

  const close = (key: string) => {
    setModals({ ...modals, [key]: false })
    setMeta({ ...meta, [key]: null })
  }
  const closeAll = () => {
    setModals(makeAllFalse(modals))
    setMeta(resetAllMeta(meta))
  }

  const open = (key: string, modalMeta?: any, closeOther?: boolean) => {
    const closeOtherModals = closeOther === undefined ? true : closeOther

    setIsOverlayVisible(true)

    setModals({
      ...(closeOtherModals ? makeAllFalse(modals) : modals),
      [key]: true
    })
    setMeta({
      ...(closeOtherModals ? resetAllMeta(meta) : meta),
      [key]: modalMeta
    })
  }

  const openAlert = (meta?: any) => {
    open('ALERT', meta)
  }
  const closeAlert = () => {
    close('ALERT')
  }

  const value: ModalContextType = {
    open,
    close,
    meta,
    closeAll,
    openAlert,
    closeAlert,
    modals
  }

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}
