import { SingleResponse } from '@/types/api'
import { User } from '@/types/user'
import useCurrentUser from './useCurrentUser'
import { useDashboardFetch, useFetch } from './useFetch'
import useUpdateEffect from './useUpdateEffect'

/**
 * Custom hook to revalidate user data.
 */
export const useCurrentUserRevalidate = (dashboard: boolean = false) => {
  const { currentUser, setCurrentUser } = useCurrentUser()
  const { data } = (dashboard ? useDashboardFetch : useFetch)<
    SingleResponse<User>
  >(`/profile`, undefined, undefined, {
    revalidateOnMount: false,
    refreshInterval: !!currentUser ? 10000 : 0,
    revalidateOnFocus: !!currentUser
  })

  useUpdateEffect(() => {
    setCurrentUser(data?.data!)
  }, [data])
}
