export const SachemoLogoGeo = () => (
  <svg
    width="155"
    height="16"
    viewBox="0 0 155 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3168_27760)">
      <path
        d="M80.9263 5.90025H85.9563C87.1213 5.90025 88.0681 6.84601 88.0681 8.00971C88.0681 8.59351 87.8304 9.11893 87.4486 9.50034C87.0667 9.88176 86.5368 10.1192 85.9563 10.1192H80.9263C80.6107 10.1192 80.3535 9.8623 80.3535 9.54705V6.46848C80.3535 6.15323 80.6107 5.89636 80.9263 5.89636V5.90025ZM85.9407 8.68692C86.3148 8.68692 86.6187 8.38334 86.6187 8.00971C86.6187 7.63608 86.3148 7.33251 85.9407 7.33251C85.5667 7.33251 85.2628 7.63608 85.2628 8.00971C85.2628 8.38334 85.5667 8.68692 85.9407 8.68692Z"
        fill="#111722"
      />
      <path
        d="M20.9696 7.30142H20.2098C19.8552 7.30142 19.5669 7.58943 19.5669 7.9436V13.9567H2.04553V0.657803C2.04553 0.303632 1.7572 0.015625 1.40265 0.015625H0.642879C0.288322 0.015625 0 0.303632 0 0.657803V14.2214C0 15.2022 0.798729 16 1.78058 16H19.828C20.8098 16 21.6085 15.2022 21.6085 14.2214V7.94749C21.6085 7.59332 21.3202 7.30532 20.9657 7.30532L20.9696 7.30142Z"
        fill="#111722"
      />
      <path
        d="M40.4237 0.0117188H27.3206C26.9661 0.0117188 26.6777 0.299726 26.6777 0.653897V1.41283C26.6777 1.767 26.9661 2.05501 27.3206 2.05501H40.299C43.5835 2.05501 46.2563 4.72491 46.2563 8.00586C46.2563 11.2868 43.5835 13.9567 40.299 13.9567H28.7349V7.94359C28.7349 7.58942 28.4466 7.30141 28.0921 7.30141H27.3323C26.9777 7.30141 26.6894 7.58942 26.6894 7.94359V14.2214C26.6894 15.2021 27.4882 16 28.47 16H40.4237C44.7641 16 48.298 12.47 48.298 8.13429V7.88132C48.298 3.54175 44.7641 0.0117188 40.4237 0.0117188Z"
        fill="#111722"
      />
      <path
        d="M73.1912 0H55.1439C54.162 0 53.3633 0.797857 53.3633 1.77864V15.3539C53.3633 15.7081 53.6516 15.9961 54.0062 15.9961H54.7659C55.1205 15.9961 55.4088 15.7081 55.4088 15.3539V9.33298H72.9302V15.3539C72.9302 15.7081 73.2185 15.9961 73.5731 15.9961H74.3328C74.6874 15.9961 74.9757 15.7081 74.9757 15.3539V9.06833C74.9757 8.08755 74.177 7.28969 73.1951 7.28969H55.4088V5.99755H73.1951C74.177 5.99755 74.9757 5.19969 74.9757 4.21891V1.77864C74.9757 0.797857 74.177 0 73.1951 0L73.1912 0ZM72.9302 3.95815H55.4088V2.04329H72.9302V3.95815Z"
        fill="#111722"
      />
      <path
        d="M126.537 0.0117188H108.49C107.508 0.0117188 106.709 0.809576 106.709 1.79036V4.60426C106.709 4.95843 106.997 5.24644 107.352 5.24644H108.112C108.466 5.24644 108.755 4.95843 108.755 4.60426V2.05501H126.276V7.30141H108.493C107.512 7.30141 106.713 8.09927 106.713 9.08005V14.2214C106.713 15.2021 107.512 16 108.493 16H126.541C127.523 16 128.321 15.2021 128.321 14.2214V1.79036C128.321 0.809576 127.523 0.0117188 126.541 0.0117188H126.537ZM126.276 9.34081V13.9567H108.755V9.34081H126.276Z"
        fill="#111722"
      />
      <path
        d="M145.2 0H143.178C137.778 0 133.387 4.39016 133.387 9.78056V15.3539C133.387 15.7081 133.675 15.9961 134.03 15.9961H134.789C135.144 15.9961 135.432 15.7081 135.432 15.3539V9.90511C135.432 5.61225 138.892 2.11335 143.17 2.0394V15.35C143.17 15.7042 143.458 15.9922 143.813 15.9922H144.573C144.927 15.9922 145.216 15.7042 145.216 15.35V2.04329C149.494 2.11724 152.954 5.61614 152.954 9.909V15.3578C152.954 15.712 153.242 16 153.597 16H154.356C154.711 16 154.999 15.712 154.999 15.3578V9.78446C154.999 4.39016 150.608 0.00389199 145.208 0.00389199L145.2 0Z"
        fill="#111722"
      />
      <path
        d="M93.3319 0.015625H81.0003C80.6457 0.015625 80.3574 0.303632 80.3574 0.657803V1.41674C80.3574 1.77091 80.6457 2.05892 81.0003 2.05892H93.1877C94.8203 2.05892 96.4216 2.73223 97.5788 3.90761C98.7048 5.05186 99.3126 6.54638 99.2853 8.11096C99.2308 11.3335 96.558 13.9567 93.3319 13.9567H81.0003C80.6457 13.9567 80.3574 14.2447 80.3574 14.5989V15.3578C80.3574 15.712 80.6457 16 81.0003 16H93.1721C97.5437 16 101.202 12.5245 101.327 8.25107C101.393 6.07545 100.591 4.0127 99.071 2.44812C97.5476 0.875754 95.5099 0.015625 93.3319 0.015625Z"
        fill="#111722"
      />
    </g>
    <defs>
      <clipPath id="clip0_3168_27760">
        <rect width="155" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const SachemoLogoEn = () => (
  <svg
    width="155"
    height="16"
    viewBox="0 0 155 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3168_28308)">
      <path
        d="M63.3573 5.89427H58.4203C57.2769 5.89427 56.3477 6.84095 56.3477 8.00579C56.3477 8.59016 56.5809 9.11609 56.9557 9.49788C57.3305 9.87966 57.8505 10.1173 58.4203 10.1173H63.3573C63.667 10.1173 63.9194 9.86019 63.9194 9.54463V6.46306C63.9194 6.1475 63.667 5.89038 63.3573 5.89038V5.89427ZM58.4356 8.68366C58.0685 8.68366 57.7702 8.37979 57.7702 8.00579C57.7702 7.6318 58.0685 7.32792 58.4356 7.32792C58.8027 7.32792 59.101 7.6318 59.101 8.00579C59.101 8.37979 58.8027 8.68366 58.4356 8.68366Z"
        fill="#111722"
      />
      <path
        d="M36.8066 2.67641C35.9347 1.00122 34.2979 0 32.4356 0C30.5732 0 28.9365 1.00122 28.0646 2.67641L21.6133 15.0533C21.5101 15.2559 21.5139 15.4896 21.6286 15.6844C21.7433 15.8792 21.946 15.9961 22.1678 15.9961H23.0129C23.2462 15.9961 23.4604 15.8636 23.5713 15.6533L29.8314 3.63087C30.3514 2.63355 31.3266 2.0375 32.4318 2.0375C33.5369 2.0375 34.5121 2.63355 35.0322 3.62698L41.2961 15.6494C41.407 15.8597 41.6173 15.9922 41.8544 15.9922H42.6995C42.9213 15.9922 43.124 15.8753 43.2387 15.6805C43.3535 15.4858 43.3611 15.2481 43.254 15.0494L36.8027 2.67251L36.8066 2.67641Z"
        fill="#111722"
      />
      <path
        d="M63.2902 0.00389662H51.1869C49.0492 0.00389662 47.0491 0.864865 45.5577 2.43487C44.0663 4.00097 43.2786 6.06574 43.3436 8.24349C43.4698 12.5211 47.0606 16 51.3513 16H63.2979C63.6459 16 63.9288 15.7117 63.9288 15.3572V14.5975C63.9288 14.243 63.6459 13.9547 63.2979 13.9547H51.1945C48.0243 13.9547 45.4048 11.3289 45.3512 8.10324C45.3245 6.53713 45.921 5.04504 47.0262 3.89579C48.162 2.71926 49.7299 2.04529 51.336 2.04529H63.2979C63.6459 2.04529 63.9288 1.757 63.9288 1.40248V0.642805C63.9288 0.288289 63.6459 0 63.2979 0L63.2902 0.00389662Z"
        fill="#111722"
      />
      <path
        d="M108.517 0.00389662H96.5701C92.2794 0.00389662 88.6886 3.48283 88.5662 7.76041C88.5012 9.94205 89.289 12.0029 90.7804 13.569C92.2756 15.1351 94.2718 16 96.4095 16H108.513C108.861 16 109.144 15.7117 109.144 15.3572V14.5975C109.144 14.243 108.861 13.9547 108.513 13.9547H96.4095C93.5338 13.9547 91.1322 11.8627 90.6504 9.02264H108.513C108.861 9.02264 109.144 8.73435 109.144 8.37984V7.62016C109.144 7.26564 108.861 6.97736 108.513 6.97736H90.6504C91.1284 4.13733 93.5338 2.04529 96.4095 2.04529H108.513C108.861 2.04529 109.144 1.757 109.144 1.40248V0.642805C109.144 0.288289 108.861 0 108.513 0L108.517 0.00389662Z"
        fill="#111722"
      />
      <path
        d="M147.149 0.0078125H141.642C137.313 0.0078125 133.791 3.59583 133.791 8.00197C133.791 10.1408 134.609 12.151 136.089 13.6548C137.569 15.1663 139.539 16 141.642 16H147.149C149.248 16 151.218 15.1663 152.697 13.6548C154.181 12.1432 155 10.1369 155 8.00197C155 3.59193 151.478 0.0078125 147.153 0.0078125H147.149ZM152.988 8.00197C152.988 9.59145 152.38 11.0874 151.275 12.2133C150.174 13.3392 148.709 13.9586 147.149 13.9586H141.642C140.078 13.9586 138.609 13.3392 137.508 12.2133C136.403 11.0913 135.795 9.59924 135.795 8.00586C135.795 4.72561 138.418 2.05699 141.638 2.05699H147.145C150.365 2.05699 152.984 4.72561 152.984 8.00586L152.988 8.00197Z"
        fill="#111722"
      />
      <path
        d="M19.9589 0.0078125H4.54772C3.32782 0.0078125 2.13087 0.522054 1.26662 1.41809C0.429136 2.28685 -0.0221108 3.42052 0.000833922 4.60484C0.0237787 5.77357 0.482674 6.87608 1.29721 7.70588C1.97408 8.39154 2.82304 8.82787 3.74848 8.97201C3.96645 9.00708 4.1959 9.02656 4.42917 9.02656H16.0698C17.4044 9.02656 18.5287 10.0862 18.5784 11.3913C18.6052 12.0886 18.3528 12.7431 17.871 13.2379C17.4121 13.7015 16.804 13.9586 16.1578 13.9586H0.631815C0.283819 13.9547 0.000833922 14.243 0.000833922 14.5975V15.3572C0.000833922 15.7117 0.283819 16 0.631815 16H16.1616C17.3432 16 18.4522 15.5325 19.2897 14.6793C20.1272 13.8301 20.5899 12.6964 20.5899 11.4887C20.5899 9.28368 18.98 7.37085 16.8385 7.03191C16.6052 6.99685 16.3834 6.98127 16.1616 6.98127H4.42917C3.78289 6.98127 3.17103 6.72415 2.71214 6.25665C2.25707 5.79305 2.00468 5.17362 2.00468 4.51524C2.00468 3.15561 3.09073 2.0531 4.42917 2.0531H19.9551C20.3031 2.0531 20.5861 1.76481 20.5861 1.4103V0.650615C20.5861 0.296098 20.3031 0.0078125 19.9551 0.0078125H19.9589Z"
        fill="#111722"
      />
      <path
        d="M86.2124 0.0078125H85.4667C85.1187 0.0078125 84.8357 0.296098 84.8357 0.650615V6.92283H67.6424V0.650615C67.6424 0.296098 67.3594 0.0078125 67.0114 0.0078125H66.2657C65.9177 0.0078125 65.6348 0.296098 65.6348 0.650615V15.3572C65.6348 15.7117 65.9177 16 66.2657 16H67.0114C67.3594 16 67.6424 15.7117 67.6424 15.3572V8.96812H84.8357V15.3572C84.8357 15.7117 85.1187 16 85.4667 16H86.2124C86.5604 16 86.8434 15.7117 86.8434 15.3572V0.650615C86.8434 0.296098 86.5604 0.0078125 86.2124 0.0078125Z"
        fill="#111722"
      />
      <path
        d="M131.057 0.054549C130.479 -0.0934909 129.883 0.15584 129.588 0.6545L121.519 12.5756C121.519 12.5756 121.431 12.5912 121.42 12.5756L113.362 0.673981C113.056 0.151945 112.456 -0.0934909 111.879 0.054549C111.282 0.21038 110.865 0.775269 110.865 1.42976V15.3572C110.865 15.7117 111.148 16 111.496 16H112.242C112.59 16 112.873 15.7117 112.873 15.3572V3.54907L119.768 13.7366C120.311 14.5391 121.317 14.8546 122.219 14.504C122.601 14.356 122.938 14.0794 123.19 13.7054L130.066 3.54517V15.3533C130.066 15.7078 130.349 15.9961 130.697 15.9961H131.443C131.791 15.9961 132.074 15.7078 132.074 15.3533V1.42587C132.074 0.771375 131.657 0.206484 131.06 0.0506524L131.057 0.054549Z"
        fill="#111722"
      />
    </g>
    <defs>
      <clipPath id="clip0_3168_28308">
        <rect width="155" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
