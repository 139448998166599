import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'

interface Props {
  isMobileMenuOpen: boolean
  isMobileUserOpen: boolean
  isMobileSearchOpen: boolean
  setIsMobileMenuOpen: (value: boolean) => void
  setIsMobileUserOpen: (value: boolean) => void
  setIsMobileSearchOpen: (value: boolean) => void
}

const useMobileHeaderState = create<Partial<Props>>()(
  devtools((set) => ({
    isMobileMenuOpen: false,
    isMobileUserOpen: false,
    isMobileSearchOpen: false,
    setIsMobileMenuOpen: (isMobileMenuOpen: boolean) =>
      set((state) => ({ ...state, isMobileMenuOpen })),
    setIsMobileUserOpen: (isMobileUserOpen: boolean) =>
      set((state) => ({ ...state, isMobileUserOpen })),
    setIsMobileSearchOpen: (isMobileSearchOpen: boolean) =>
      set((state) => ({ ...state, isMobileSearchOpen }))
  }))
)

export function useMobileHeader() {
  return useMobileHeaderState<{
    isMobileMenuOpen: boolean | undefined
    isMobileUserOpen: boolean | undefined
    isMobileSearchOpen: boolean | undefined
    setIsMobileMenuOpen: ((value: boolean) => void) | undefined
    setIsMobileUserOpen: ((value: boolean) => void) | undefined
    setIsMobileSearchOpen: ((value: boolean) => void) | undefined
  }>(
    (state) => ({
      isMobileMenuOpen: state.isMobileMenuOpen,
      isMobileUserOpen: state.isMobileUserOpen,
      isMobileSearchOpen: state.isMobileSearchOpen,
      setIsMobileMenuOpen: state.setIsMobileMenuOpen,
      setIsMobileUserOpen: state.setIsMobileUserOpen,
      setIsMobileSearchOpen: state.setIsMobileSearchOpen
    }),
    shallow
  )
}
