export enum OrderStatusType {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  ON_WAY = 'on_way',
  DELIVERED = 'delivered',
  CANCELED = 'canceled',
  RETURNED = 'returned',
  READY_FOR_SHIPMENT = 'ready'
}

export enum OrderStatusId {
  Pending = 1,
  Confirmed = 2,
  Shipped = 4,
  Delivered = 5,
  Cancelled = 6,
  Returned = 7,
  ReadyForShipment = 8,
  Rejected = 9
}
