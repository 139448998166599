'use client'

import classNames from 'classnames'
import useFooterRef from '@/hooks/useFooterRef'
import useHeaderRef from '@/hooks/useHeaderRef'
import { PropsWithChildren, useEffect, useRef } from 'react'
import { useWindowSize } from 'usehooks-ts'

export type ContainerProps = PropsWithChildren<{
  className?: string
  plain?: boolean
}>

const Container = ({ children, className, plain = false }: ContainerProps) => {
  const footerRef = useFooterRef()
  const headerRef = useHeaderRef()
  const ref = useRef<HTMLDivElement | null>(null)
  const { height } = useWindowSize()

  useEffect(() => {
    if (
      window &&
      footerRef.current &&
      headerRef.current &&
      height &&
      ref.current &&
      !plain
    ) {
      ref.current.style.minHeight = `${
        height -
        footerRef.current?.clientHeight -
        headerRef.current?.clientHeight -
        67
      }px`
    }
  }, [footerRef, headerRef, height, plain])

  return (
    <div
      ref={ref}
      className={classNames(
        'mx-auto w-[1300px] max-w-full px-5 pt-5 sm:max-w-[95%] sm:px-0 sm:pt-9',
        className
      )}
    >
      {children}
    </div>
  )
}

export default Container
