import Alert from '@/components/Alert'
import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'
import FormItem from '@/components/FormItem'
import Input from '@/components/Input'
import Radio from '@/components/Radio'
import Status, { ProcessStatus } from '@/components/Status'
import useFormValidation from '@/hooks/useFormValidation'
import useTranslate from '@/hooks/useTranslate'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { Collection } from '@/types/collection'
import { Form } from 'antd'
import classNames from 'classnames'
import { AddCircle } from 'iconsax-react'
import { PropsWithChildren } from 'react'
import { createUseStyles } from 'react-jss'

export type AddToCollectionFormProps = PropsWithChildren<{
  className?: string
  collections: Partial<Collection>[]
  isLoading?: boolean
  serverValidationErrors?: any
  data?: any
  onCreateAndSave?: (data: any) => void
  onAddToCollection?: (collection: any[], data: any) => void
  onCreateCollection?: () => void
}>

const AddToCollectionForm = ({
  className,
  isLoading,
  serverValidationErrors,
  collections,
  data,
  onCreateCollection,
  onCreateAndSave,
  onAddToCollection
}: AddToCollectionFormProps) => {
  const classes = useStyles()
  const t = useTranslate()
  const {
    rules: { required },
    getServerError
  } = useFormValidation({ serverValidationErrors })

  const showCreateCollection = data?.isCreateNewCollection ?? false
  const [form] = Form.useForm()
  const collection = Form.useWatch('collection', { form, preserve: true })
  const name = Form.useWatch('name', { form, preserve: true })
  const visibility = Form.useWatch('visibility', { form, preserve: true })

  useUpdateEffect(
    () => collection && onAddToCollection?.(collection, data),
    [collection]
  )

  return (
    <div className={classNames('', className)}>
      <Form
        initialValues={{ visibility: 1 }}
        form={form}
        onFinish={onCreateAndSave}
        layout="vertical"
      >
        {!collections?.length && (
          <div className="flex flex-col items-center  justify-center">
            <Status status={ProcessStatus.EMPTY} />
            <p className="pt-4">{t('common.no_collections')}</p>
          </div>
        )}

        {showCreateCollection && (
          <>
            <FormItem
              name="name"
              label={t('common.name')}
              rules={[required()]}
              validateStatus={getServerError('name') ? 'error' : undefined}
              help={getServerError('name')}
            >
              <Input
                name="name"
                allowClear
                placeholder={t('common.fill_collection_name')}
              />
            </FormItem>

            <FormItem
              name="visibility"
              validateStatus={
                getServerError('visibility') ? 'error' : undefined
              }
              help={getServerError('visibility')}
            >
              <Radio.Group
                options={[
                  { label: t('common.public_collection'), value: 1 },
                  { label: t('common.private_collection'), value: 0 }
                ]}
                className="-mx-2"
              />
            </FormItem>

            {visibility === 0 && (
              <Alert
                size="small"
                description={t('common.private_collection_alert')}
                type="info"
              />
            )}
          </>
        )}

        {!showCreateCollection && !!collections?.length && (
          <>
            <FormItem
              name="collection"
              className={classes.checkboxGroupWrapper}
            >
              <Checkbox.Group
                className={classNames(
                  'inline-flex flex-col items-start pb-1',
                  classes.checkboxGroup
                )}
                options={collections.map(({ name: label, id: value = '' }) => ({
                  label,
                  value
                }))}
              />
            </FormItem>

            <Button
              icon={<AddCircle size={16} className="-mt-0.5" />}
              type="link"
              onClick={onCreateCollection}
              htmlType="button"
            >
              {t('common.add_collection')}
            </Button>
          </>
        )}

        {showCreateCollection && (
          <Button
            disabled={
              showCreateCollection
                ? !(form.isFieldTouched('name') && !!name)
                : !(form.isFieldTouched('collection') && !!collection?.length)
            }
            block
            className="mt-8"
            htmlType="submit"
            loading={isLoading}
            type="primary"
          >
            {!!data.id ? t('common.create_and_add') : t('common.create')}
          </Button>
        )}

        {!collections?.length && !showCreateCollection && (
          <Button
            block
            onClick={onCreateCollection}
            className="mt-8"
            htmlType="button"
            type="primary"
          >
            {t('common.add_collection')}
          </Button>
        )}
      </Form>
    </div>
  )
}

const useStyles = createUseStyles({
  checkboxGroup: {
    '& > .ant-checkbox-wrapper': {
      padding: '5px 0!important'
    }
  },
  checkboxGroupWrapper: {
    '& .ant-form-item-control-input-content': {
      maxHeight: '160px',
      overflowY: 'auto',
      '-webkit-overflow-scrolling': 'touch'
    }
  }
})

export default AddToCollectionForm
