'use client'

import { useWindowSize } from 'usehooks-ts'

export enum Devices {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop'
}
const config: { [x: string]: number } = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
}

export const useBreakpoint = () => {
  const { width } = useWindowSize()
  let device = null

  if (width < config.sm) {
    device = Devices.Mobile
  } else if (width < config.lg) {
    device = Devices.Tablet
  } else {
    device = Devices.Desktop
  }

  const isMobile = device === Devices.Mobile
  const isTablet = device === Devices.Tablet
  const isDesktop = device === Devices.Desktop

  return { device, isMobile, isTablet, isDesktop }
}
