import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import usePasswordStrengthChecker from '@/hooks/usePasswordStrengthChecker'
import {
  Dislike,
  InfoCircle,
  Like1,
  MedalStar,
  TickCircle
} from 'iconsax-react'
import { ReactNode } from 'react'

export type PasswordStrengthCheckerProps = {
  className?: string
  password: string
  minimal?: boolean
}

const PasswordStrengthChecker = ({
  className,
  password,
  minimal
}: PasswordStrengthCheckerProps) => {
  const classes = useStyles()
  const { passed, failed, scoreWord, percentage, scoreKey } =
    usePasswordStrengthChecker(password)
  const icons: { [key: string]: ReactNode } = {
    weak: <Dislike size="20" color="#FF0000" variant="Outline" />,
    moderate: <Like1 size="20" color="#FFC336" variant="Outline" />,
    strong: <MedalStar size="20" color="#2BBB13" variant="Outline" />
  }

  return (
    <div className={classNames('font-firago', className)}>
      <div className="mt-3 overflow-hidden rounded-sm bg-gray-300">
        <span
          className={classNames('block h-[4px]', {
            'bg-red': percentage <= 40,
            'bg-yellow': percentage > 40 && percentage <= 80,
            'bg-green': percentage > 80
          })}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <p
        className={classNames('items-center pt-1 text-sm flex', {
          'text-red': percentage <= 40,
          'text-yellow': percentage > 40 && percentage <= 80,
          'text-green': percentage > 80,
          'pb-3': !minimal
        })}
      >
        {icons[scoreKey!]}
        <span className="pl-1.5">{scoreWord}</span>
      </p>
      {!minimal && (
        <>
          <p className="pb-3 text-sm text-black">
            როგორ უნდა დააყენო ძლიერი პაროლი?
          </p>
          <ul className="grid gap-2">
            {passed.map((rule, index) => (
              <li
                key={index}
                className="flex items-center text-sm leading-tight text-green"
              >
                <TickCircle size="20" variant="Outline" className="mr-1.5" />{' '}
                {rule.label}
              </li>
            ))}
            {failed.map((rule, index) => (
              <li
                key={index}
                className="flex items-center text-sm leading-tight text-gray-400"
              >
                <InfoCircle size="20" variant="Outline" className="mr-1.5" />{' '}
                {rule.label}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

const useStyles = createUseStyles({})

export default PasswordStrengthChecker
