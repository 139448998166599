'use client'

import useLightbox from '@/hooks/useLightbox'
import { animated, useTransition } from '@react-spring/web'
import classNames from 'classnames'
import { ArrowLeft, ArrowRight, CloseCircle } from 'iconsax-react'
import { useLocale } from 'next-intl'
import { useRef, useState } from 'react'
import 'swiper/css/effect-coverflow'
import { Keyboard, Navigation } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import IconButton from '../IconButton'
import Image from '../Image'
import { SachemoLogoEn, SachemoLogoGeo } from '../SVG'

export type LightboxProps = {}

export const Lightbox = ({}: LightboxProps) => {
  const locale = useLocale()
  const swiperRef = useRef<SwiperRef>(null)
  const prev = useRef(null)
  const next = useRef(null)
  const [swiperEnded, setSwiperEnded] = useState(false)
  const { currentIndex, setCurrentIndex, data } = useLightbox()
  const transitions = useTransition(currentIndex !== null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return transitions(
    (style, visible) =>
      visible &&
      data && (
        <animated.div
          style={style}
          className="fixed top-0 left-0 w-full h-full bg-white z-[100]"
        >
          <div
            className="absolute max-lg:left-1/2 max-lg:-translate-x-1/2 lg:left-12 max-lg:top-8 lg:top-6 z-10 cursor-pointer"
            onClick={() => setCurrentIndex(null)}
          >
            {locale === 'ka' && <SachemoLogoGeo />}
            {locale === 'en' && <SachemoLogoEn />}
          </div>
          <IconButton
            className="max-lg:hidden absolute right-7 top-7 z-10 [&_path:first-child]:!fill-transparent !border !border-black bg-transparent"
            icon={<CloseCircle size="32" variant="Bulk" />}
            type="secondary-dark"
            onClick={() => setCurrentIndex(null)}
          />
          <div className="max-lg:px-5 w-full h-full flex items-center">
            <Swiper
              modules={[Keyboard, Navigation]}
              className={classNames(
                'h-full w-full [&_.swiper-slide:not(.swiper-slide-active)]:scale-75 [&_.swiper-slide:not(.swiper-slide-active):hover]:opacity-30 [&_.swiper-slide:not(.swiper-slide-active)]:opacity-30 [&_.swiper-slide]:transition-[transform,opacity] [&_.swiper-slide]:duration-700'
              )}
              onSlideChange={({ activeIndex, isEnd }) => {
                setCurrentIndex(activeIndex)
                setSwiperEnded(isEnd)
              }}
              slideToClickedSlide
              ref={swiperRef}
              keyboard
              watchSlidesProgress
              navigation={{
                prevEl: prev.current,
                nextEl: next.current
              }}
              initialSlide={currentIndex as number}
              grabCursor
              slidesPerView={1}
              spaceBetween={20}
              centeredSlides={true}
              speed={700}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 0
                }
              }}
            >
              {data?.map((src, index) => (
                <SwiperSlide
                  className={classNames(
                    '!max-h-[70vh] !h-full !flex items-center justify-center !my-auto'
                  )}
                  key={index}
                >
                  <Image
                    src={src}
                    width={100}
                    height={100}
                    className="max-lg:w-full lg:!h-full lg:!w-auto !max-w-full rounded-md lg:object-cover"
                    alt=""
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="absolute w-full bottom-7 left-0 px-7 flex justify-between z-20">
            <div className="max-lg:hidden">
              <ul className="flex">
                {data?.map((src, index: number) => (
                  <li
                    key={index}
                    className={classNames(
                      'overflow-hidden w-[40px] h-[55px] block bg-gray-300 rounded-md mr-3 cursor-pointer transition-all duration-500'
                    )}
                    onClick={() => swiperRef.current?.swiper.slideTo(index)}
                  >
                    <Image
                      src={src}
                      width={100}
                      height={100}
                      className={classNames(
                        'transition-all duration-500 !h-full !w-full object-cover opacity-30 hover:opacity-100',
                        {
                          '!opacity-100': index === currentIndex
                        }
                      )}
                      alt=""
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className="max-lg:hidden flex items-center">
              <IconButton
                onClick={() => swiperRef.current?.swiper.slidePrev()}
                icon={<ArrowLeft size={20} />}
                size="small"
                className="mr-3"
                disabled={currentIndex === 0}
              />
              <IconButton
                onClick={() => swiperRef.current?.swiper.slideNext()}
                icon={<ArrowRight size={20} />}
                size="small"
                disabled={swiperEnded}
              />
            </div>

            <div className="lg:hidden flex items-center justify-center w-full">
              <IconButton
                onClick={() => swiperRef.current?.swiper.slidePrev()}
                icon={<ArrowLeft size={20} />}
                size="default"
                className=""
                disabled={currentIndex === 0}
              />
              <IconButton
                className="mx-8 [&_path:first-child]:!fill-transparent !border !border-black bg-transparent"
                icon={<CloseCircle size="32" variant="Bulk" />}
                type="secondary-dark"
                onClick={() => setCurrentIndex(null)}
              />
              <IconButton
                onClick={() => swiperRef.current?.swiper.slideNext()}
                icon={<ArrowRight size={20} />}
                size="default"
                disabled={swiperEnded || data.length <= 1}
              />
            </div>
          </div>
        </animated.div>
      )
  )
}
