import { useMobileNavigationContext } from '../context/MobileNavigationContext'

const useMobileNavigation = () => {
  const {
    isMenuOpen,
    toggleMenu,
    closeMenu,
    isSearchOpen,
    toggleSearch,
    closeSearch,
    isUserOpen,
    toggleUser,
    closeUser
  } = useMobileNavigationContext()

  return {
    isMenuOpen,
    toggleMenu,
    closeMenu,
    isSearchOpen,
    toggleSearch,
    closeSearch,
    isUserOpen,
    toggleUser,
    closeUser
  }
}

export default useMobileNavigation
