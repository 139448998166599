import Link from '@/components/Link'
import useTranslate from '@/hooks/useTranslate'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import useDetectScroll, {
  Axis,
  Direction
} from '@smakss/react-scroll-direction'
import classNames from 'classnames'
import { ArrowRight2 } from 'iconsax-react'
import { useLocale } from 'next-intl'
import { usePathname } from 'next/navigation'
import { useRef, useState } from 'react'
import Container from '../Container'

export type HeaderCategoriesProps = {
  className?: string
  items: any[]
}

const HeaderCategories = ({ className, items = [] }: HeaderCategoriesProps) => {
  const [showCategories, setShowCategories] = useState(false)
  const [forceHide, setForceHide] = useState(false)
  const locale = useLocale()
  const [isCategoriesFixed, setIsCategoriesFixed] = useState(false)
  const { scrollDir, scrollPosition } = useDetectScroll({ axis: Axis.Y })
  const timeout = useRef<any>(null)
  const t = useTranslate()
  const pathname = usePathname()
  const pathParts = pathname?.split('/')
  const currentCategory = pathParts
  const currentRootSlug = pathParts[3] && decodeURIComponent(pathParts[3])
  const firstCategory = items?.find(({ key }) => key === 'products')
    ?.children?.[0]
  const isCategoryActive = (slug: string[]) => {
    const categorySlug = slug.join('/')

    return (
      decodeURIComponent(pathname.replace(`/${locale}/categories/`, '')) ===
      categorySlug
    )
  }

  useUpdateEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    if (scrollPosition.top < 150) {
      setIsCategoriesFixed(false)
    }

    if (scrollPosition.top > 150 && scrollDir === Direction.Up) {
      setShowCategories(true)
      setIsCategoriesFixed(true)
    } else {
      setShowCategories(false)
    }
  }, [scrollDir, scrollPosition.top])

  useUpdateEffect(() => {
    if (pathname.includes('categories')) {
      if (pathname.includes('categories/')) {
        setForceHide(true)

        setTimeout(() => {
          setForceHide(false)
        }, 0)
      } else {
        setForceHide(true)
      }
    }
  }, [pathname])

  return (
    <div className="h-[64px]">
      <nav
        className={classNames(
          'border-b border-gray-300 bg-white font-firago',
          className,
          {
            'fixed z-10 w-full transition-all duration-500': isCategoriesFixed,
            'top-[65px]': showCategories,
            'top-[-69px]': !showCategories
          }
        )}
      >
        <ul className="relative no-scrollbar flex items-center gap-6 max-lg:overflow-x-auto p-5 sm:justify-center sm:_overflow-x-hidden sm:px-0">
          {items.map(({ name, key, url, children }) => (
            <li key={key} className="group/root">
              <Link
                onMouseEnter={() => setForceHide(false)}
                className="group-hover/root:text-yellow"
                href={url}
              >
                {name}
              </Link>
              {children && (
                <div
                  className={classNames(
                    'pt-[20.5px] -mt-[5px] hidden group-hover/root:block z-10 left-0 w-full mt-[0.5px] absolute border-b border-gray-300',
                    { '!hidden': forceHide }
                  )}
                >
                  <div className="bg-white overflow-hidden">
                    <Container className="!pt-0 !min-h-[auto]">
                      <div className="grid grid-cols-[200px_1fr] relative">
                        <ul className="border-r border-gray-300 py-8 grid grid-cols-1">
                          {children.map(({ name, id, slug, children }: any) => (
                            <li className="group" key={id}>
                              <Link
                                className={classNames(
                                  'flex items-center justify-between pr-6 text-sm !py-1.5',
                                  {
                                    'text-gray-400': currentRootSlug !== slug,
                                    'text-yellow':
                                      currentRootSlug === slug ||
                                      (!currentRootSlug &&
                                        firstCategory?.slug === slug)
                                  }
                                )}
                                href={`/categories/${slug}`}
                              >
                                {name}
                                <ArrowRight2
                                  className={classNames(
                                    'group-hover:opacity-100 opacity-0 -translate-x-[10px] group-hover:translate-x-0 !duration-[0.05s] !transition-[transform, opacity, fill]',
                                    {
                                      '!opacity-100 !translate-x-0':
                                        currentRootSlug === slug ||
                                        (!currentRootSlug &&
                                          firstCategory?.slug === slug)
                                    }
                                  )}
                                  size="16"
                                />
                              </Link>

                              <ul
                                className={classNames(
                                  'mt-[5px] h-full group-hover:grid group-hover:z-[10] bg-white hidden px-6 py-8 -mt-1 absolute left-[200px] top-0 grid grid-cols-5 w-[calc(100%-200px)]',
                                  {
                                    '!grid':
                                      currentCategory === slug ||
                                      firstCategory?.slug === slug
                                  }
                                )}
                              >
                                {children.map(
                                  ({
                                    slug: _slug,
                                    id,
                                    name,
                                    children
                                  }: any) => (
                                    <li key={id}>
                                      <Link
                                        className={classNames('font-[500]', {
                                          'text-yellow': isCategoryActive([
                                            slug,
                                            _slug
                                          ])
                                        })}
                                        href={`/categories/${slug}/${_slug}`}
                                      >
                                        {name}
                                      </Link>
                                      {!!children && !!children?.length && (
                                        <ul className="mt-4 grid grid-cols-1 gap-1">
                                          {children.map(
                                            ({
                                              slug: __slug,
                                              id,
                                              name
                                            }: any) => (
                                              <li key={id}>
                                                <Link
                                                  className={classNames({
                                                    'text-yellow':
                                                      isCategoryActive([
                                                        slug,
                                                        _slug,
                                                        __slug
                                                      ])
                                                  })}
                                                  href={`/categories/${slug}/${_slug}/${__slug}`}
                                                >
                                                  {name}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            </li>
                          ))}
                          <li key="view_all">
                            <Link
                              className="text-gray-400"
                              href={`/categories`}
                            >
                              {t('common.see_all')}
                            </Link>
                          </li>
                        </ul>

                        {/* <div className="py-8 px-6">
                          {!!children &&
                            !!children?.length &&
                            children.map(
                              ({ id, children }: any) =>
                                id === activeCategory && (
                                  <ul key={id} className="grid grid-cols-5">
                                    {children?.map(
                                      ({ name, id, slug, children }: any) => (
                                        <li key={id}>
                                          <Link
                                            href={`/categories/${slug}`}
                                            className="font-[500]"
                                          >
                                            {name}
                                          </Link>
                                          {!!children && !!children?.length && (
                                            <ul className="mt-2.5 grid grid-cols-1 gap-1.5">
                                              {children?.map(
                                                ({ name, id, slug }: any) => (
                                                  <li key={id}>
                                                    <Link
                                                      href={`/categories/${slug}`}
                                                    >
                                                      {name}
                                                    </Link>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          )}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )
                            )}
                        </div> */}
                      </div>
                    </Container>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default HeaderCategories
