import classNames from 'classnames'
import { Status } from 'iconsax-react'
import { EventHandler, ForwardedRef, ReactNode, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'

export type IconButtonSize = 'default' | 'small' | 'medium' | 'xsmall'

export type IconButtonType =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'secondary-dark'
  | 'danger'

export type IconButtonProps = {
  type?: IconButtonType
  size?: IconButtonSize
  disabled?: boolean
  loading?: boolean
  active?: boolean
  icon?: ReactNode
  className?: string
  htmlType?: 'button' | 'submit' | 'reset'
  onClick?: EventHandler<any>
  style?: any
}

const IconButton = forwardRef(
  (
    {
      size = 'default',
      type = 'primary',
      disabled,
      loading,
      icon,
      className,
      active,
      style,
      htmlType = 'button',
      onClick
    }: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const classes = useStyles()

    return (
      <button
        ref={ref}
        onClick={onClick}
        style={style}
        className={classNames(
          ' flex items-center font-firago transition-all ease-in-out',
          {
            'bg-black text-white hover:bg-orange active:bg-yellow':
              type === 'primary' && !disabled,
            'bg-gray-200 text-black hover:bg-gray-300 active:bg-gray-200':
              (type === 'secondary' && !disabled) || (loading && !disabled),
            'text-white bg-red': type === 'danger',
            'rounded-full px-4 py-4 text-sm': size === 'default',
            'rounded-full p-2.5 text-sm': size === 'medium',
            'rounded-full px-2 py-2 text-sm': size === 'small',
            'rounded-full px-1.5 py-1.5 text-xs': size === 'xsmall',
            'bg-gray-200 text-gray-400': disabled,
            'pointer-events-none relative select-none': loading && !disabled,
            'bg-gray-200 text-black hover:bg-black hover:text-white active:bg-black':
              type === 'secondary-dark' && !disabled && !active,
            '!bg-black !text-white':
              active && type === 'secondary-dark' && !disabled
          },
          className
        )}
        disabled={disabled}
        type={htmlType}
      >
        {icon && (
          <span
            className={classNames('[&.small>svg]:h-5 [&.small>svg]:w-5', size, {
              'opacity-0': loading && !disabled
            })}
          >
            {icon}
          </span>
        )}
        {loading && !disabled && (
          <span
            className={classNames(
              'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
            )}
          >
            <Status
              size="20"
              className={classNames('text-back animate-spin', {})}
            />
          </span>
        )}
      </button>
    )
  }
)

IconButton.displayName = 'IconButton'

const useStyles = createUseStyles({})

export default IconButton
