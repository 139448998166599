'use client'

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useRef
} from 'react'

const FooterRefContext = createContext<
  React.RefObject<HTMLElement> | undefined
>(undefined)

export const useFooterRefContext = () => {
  const context = useContext(FooterRefContext)
  if (!context) {
    throw new Error(
      'useFooterRefContext must be used within a FooterRefProvider'
    )
  }
  return context
}

export const FooterRefProvider = ({ children }: PropsWithChildren) => {
  const footerRef = useRef<HTMLElement | null>(null)

  return (
    <FooterRefContext.Provider value={footerRef}>
      {children}
    </FooterRefContext.Provider>
  )
}
