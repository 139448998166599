import { Menu as AntMenu, MenuItemProps, MenuProps } from 'antd'
import classNames from 'classnames'

const Menu = ({ className, rootClassName, ...props }: MenuProps) => {
  return (
    <AntMenu
      {...props}
      className={classNames(className)}
      rootClassName={classNames('!shadow-none !p-0 !border-0', rootClassName)}
    />
  )
}

const MenuItem = ({
  className,
  paddingTop = 'default',
  ...props
}: MenuItemProps & { paddingTop?: 'small' | 'default' }) => {
  return (
    <AntMenu.Item
      {...props}
      className={classNames(
        '!p-0 text-sm hover:!bg-transparent hover:!text-yellow',
        {
          '!mt-3': paddingTop === 'default',
          '!mt-2': paddingTop === 'small'
        },
        className
      )}
    />
  )
}

Menu.item = MenuItem

export default Menu
