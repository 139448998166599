import { OrderStatusType } from '@/constants/orders'
import { ProductStockTypes } from '@/constants/products'
import { Brand } from '@/types/brand'
import { Cart } from '@/types/cart'
import { Category } from '@/types/category'
import { Order } from '@/types/order'
import { Product, ProductOption, ProductVariant } from '@/types/product'
import { Resource } from '@/types/resource'
import { User } from '@/types/user'
import * as _ from 'lodash'

// Define a generic Translation type that can have multiple fields
type Translation = { [key: string]: string }

// Define a generic type for TranslatableField
type TranslatableField = { translations: Translation[] }

// Define a generic Record type that can represent any structure
type RecordType = {
  [key: string]: any
}

// Define the Fields type, which can contain either strings or nested field structures
type Fields = (string | [string, string[]])[]

export const formatToPrice = (price: number) => {
  return (price / 100).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const formatToNumber = (number: number) => {
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1
  })
}

export function fileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve((reader.result as string).split(',')[1])
    reader.onerror = (error) => reject(error)
  })
}

export const getUserInitials = ({ first_name, last_name }: User) => {
  return `${first_name[0]}${last_name[0]}`
}

export const translateRecords = <T extends RecordType>(
  data: T,
  fields: Fields,
  lang: 'en' | 'ka' = 'ka'
): T => {
  const isSingleRecord = !Array.isArray(data)
  const actualData = isSingleRecord ? [data] : data
  const translatedData = actualData.map((record) => {
    const translatedRecord = _.cloneDeep(record)

    fields.forEach((field) => {
      if (Array.isArray(field)) {
        // Handle nested fields (e.g., ['status', ['name', 'description']])
        const [parentField, subFields] = field

        // Get the first translation for the parent field
        const parentTranslations: Translation[] = _.get(
          translatedRecord,
          `${parentField}.translations`,
          []
        )
        const parentTranslation = parentTranslations?.find(
          ({ language }) => language === lang
        )

        if (parentTranslation) {
          // Replace the parent field's translations with the single translation for all specified subfields
          subFields.forEach((subField) => {
            if (parentTranslation[subField]) {
              _.set(
                translatedRecord,
                `${parentField}.${subField}`,
                parentTranslation[subField]
              )
            }
          })
        }

        // Clean up the translations array
        _.unset(translatedRecord, `${parentField}.translations`)
      } else {
        // Handle root-level fields (e.g., 'name')
        const translations: Translation[] = _.get(
          translatedRecord,
          `translations`,
          []
        )
        const translation = translations?.find(
          ({ language }) => language === lang
        )

        if (translation) {
          // Replace translatable fields at the root level
          _.set(translatedRecord, field, translation[field])
        }

        // Clean up the translations array at the root level
        _.unset(translatedRecord, `translations`)
      }
    })

    return translatedRecord
  })

  return isSingleRecord ? translatedData[0] : translatedData
}

export const resourceToOption = (resources: Resource[] = []) =>
  resources.map(({ id: value, name: label }) => ({ value, label }))

export const convertToCascaderOptions = (data: Resource[]): any[] => {
  return data.map(({ id, name, children }) => ({
    value: id,
    label: name,
    children: children ? convertToCascaderOptions(children) : undefined
  }))
}

export const findCategoryPath = (
  categories: Resource[],
  categoryId: number | string
): (number | string)[] | null => {
  for (const category of categories) {
    // If the current category matches the categoryId, return its path
    if (category.id === categoryId) {
      return [category.id]
    }

    // If the category has children, search recursively
    if (category.children) {
      const childPath = findCategoryPath(category.children, categoryId)

      // If the category was found in the children, prepend the current category ID
      if (childPath) {
        return [category.id, ...childPath]
      }
    }
  }

  // Return null if the category is not found in this branch
  return null
}

export const getBrandLogoUrl = (brand: Brand & { assets: any[] }) => {
  const logoUrl = brand?.assets?.find(({ type }) => type === 'logo')?.src

  return logoUrl ?? null
}

export const getProductThumbnail = (assets: any[]) => {
  return (
    assets.find(({ type, primary }) => type === 'image' && primary)?.src ?? ''
  )
}

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes'

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const k = 1024 // base for KB/MB/GB

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const value = bytes / Math.pow(k, i)

  return `${_.round(value, decimals)} ${sizes[i]}`
}

export const getCategoryTree = (category: any): any[] => {
  const categoryTree: any[] = []

  // Traverse up the tree
  let currentCategory: any | null = category

  while (currentCategory) {
    const categoryNode = {
      url: `/categories/${currentCategory.slug}`,
      name: currentCategory.name
    }

    // Prepend each category to maintain parent-to-child order
    categoryTree.unshift(categoryNode)

    // Move up to the parent category
    currentCategory = currentCategory.parent || null
  }

  return categoryTree
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const removeHTMLTags = (str: string) =>
  str?.replace(/<\/?[^>]+(>|$)/g, '')

export const getCategoryHierarchy = (category?: Category | null): any => {
  const hierarchy = []
  const pathSegments: any[] = []

  let currentCategory = category
  while (currentCategory) {
    // Add the current category's slug and name to the hierarchy
    hierarchy.push({
      slug: currentCategory.slug,
      name: currentCategory.name
    })

    // Move up to the parent category
    currentCategory = currentCategory.parent
  }

  // Now reverse the hierarchy to start from the top-level parent
  hierarchy.reverse()

  // Generate URLs for each category based on the accumulated path
  hierarchy.forEach((item: any, index) => {
    // Build the URL incrementally
    pathSegments.push(item.slug)
    item.url = `/categories/${pathSegments.join('/')}`
  })

  return hierarchy
}

export const generateCategoryUrlByParent = (
  currentCategory: Category,
  currentChild?: Category
): string | null => {
  if (currentCategory) {
    // Start with the child's slug
    let slugs = currentChild ? [currentChild.slug] : []

    // Include the current category slug
    slugs.unshift(currentCategory.slug)

    // Recursively get the slug from the parent categories
    let parent = currentCategory.parent // Start with the parent of the current category

    while (parent) {
      slugs.unshift(parent.slug) // Add parent slug at the beginning
      parent = parent.parent // Move up to the next parent
    }

    // Concatenate slugs to form the URL
    return `/${slugs.join('/')}`
  }

  return `${currentChild?.slug ? `/${currentChild.slug}` : ''}`
}

export const getOrderStatus = (order: Order, isBrand?: boolean) => {
  const status = order.status?.id

  switch (status) {
    case 1:
      return OrderStatusType.PENDING

    case 2:
      return OrderStatusType.ACCEPTED

    case 9:
      return OrderStatusType.REJECTED

    case 8:
      return isBrand
        ? OrderStatusType.READY_FOR_SHIPMENT
        : OrderStatusType.ACCEPTED

    default:
      return OrderStatusType.PENDING
  }
}

export const getOrderStatusId = (status: 'all' | OrderStatusType) => {
  switch (status) {
    case OrderStatusType.PENDING:
      return 1

    case OrderStatusType.ACCEPTED:
      return 2

    case OrderStatusType.DELIVERED:
      return 5

    case OrderStatusType.CANCELED:
      return 6

    case OrderStatusType.REJECTED:
      return 9

    default:
      return undefined
  }
}

export const getOrderItemsCount = (items: Order['order_items']) => {
  return items?.reduce((prev, curr) => prev + curr.quantity, 0) ?? 0
}

export const cartesianProduct = (arrays: any[][]): any[][] => {
  return arrays.length === 0
    ? []
    : arrays.reduce<any[][]>(
        (acc, curr) =>
          acc.flatMap((x) =>
            curr.map((y) => (Array.isArray(x) ? [...x, y] : [x, y]))
          ),
        [[]]
      )
}

export const reshapeProductOptions = (options: ProductOption[]) => {
  return options.map(({ value, values }) => {
    return { id: value, values: values.map((val) => val.value) }
  })
}

export const reshapeProductVariants = (variants: ProductVariant[]) => {
  return variants.map(({ id, combination, stock, price, stock_type_id }) => {
    return {
      id,
      combination: combination.map((comb) => ({
        option_value_id: comb.id,
        option_id: comb.option_id
      })),
      stock,
      stock_type_id,
      price
    }
  })
}

export const getProductStock = (product: Product) =>
  product?.variants?.length
    ? product?.variants?.reduce(
        (prev: number, curr: any) =>
          prev +
          (curr.stock_type_id === ProductStockTypes.ByOrder ? 100 : curr.stock),
        0
      )
    : product?.stock

export const getProductPrice = (product: Partial<Product>) =>
  product?.variants?.length
    ? Math.min(...product?.variants?.map(({ price }: any) => parseInt(price)))
    : product.price

export const isProductAvailable = (product: Partial<Product>) =>
  product.variants?.some(
    ({ stock, stock_type_id }) =>
      parseInt(stock) > 0 || stock_type_id == ProductStockTypes.ByOrder
  )

export const getDefaultVariant = (product: Partial<Product>) => {
  const defaultVariant = product?.variants?.find(
    ({ is_default }: any) => is_default
  )

  if (!!defaultVariant) {
    return defaultVariant
  }

  if (product?.variants?.length === 1) {
    return product?.variants[0]
  }
}

export const getAvailableQuantity = (
  currentVariant: ProductVariant,
  cart: Cart
) => {
  const selectedVariantQuantityInCart =
    cart?.lines?.find((line) => line.variant_id == currentVariant?.id)
      ?.quantity ?? 0

  const availableQuantity =
    currentVariant &&
    (currentVariant?.stock_type_id === ProductStockTypes.ByOrder
      ? 100 - selectedVariantQuantityInCart
      : (currentVariant.stock as any) - selectedVariantQuantityInCart)

  return availableQuantity
}

export const getTotalStock = (product: Partial<Product>) => {
  return product.variants
    ?.filter(
      ({ is_default, stock_type_id }) =>
        !is_default && stock_type_id === ProductStockTypes.Quantity
    )
    ?.reduce((prev, { stock }) => prev + (stock ?? 0), 0)
}

export const productHasVariants = (product: Partial<Product>) => {
  return !!product.variants?.filter(({ is_default }) => !is_default)?.length
}

export const productHasQuantityVariants = (product: Partial<Product>) => {
  return !!product.variants?.filter(
    ({ is_default, stock_type_id }) =>
      !is_default && stock_type_id === ProductStockTypes.Quantity
  )?.length
}

export const productHasByOrderVariants = (product: Partial<Product>) => {
  return !!product.variants?.filter(
    ({ is_default, stock_type_id }) =>
      !is_default && stock_type_id === ProductStockTypes.ByOrder
  )?.length
}

export const getNonDefaultQuantityVariantsCount = (
  product: Partial<Product>
) => {
  return (
    product.variants?.filter(
      ({ is_default, stock_type_id }) =>
        !is_default && stock_type_id === ProductStockTypes.Quantity
    )?.length ?? 0
  )
}

export const getNonDefaultByOrderVariantsCount = (
  product: Partial<Product>
) => {
  return (
    product.variants?.filter(
      ({ is_default, stock_type_id }) =>
        !is_default && stock_type_id === ProductStockTypes.ByOrder
    )?.length ?? 0
  )
}
