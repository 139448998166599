import ka from './ka/common.json'
import en from './en/common.json'

export const locales = [
  {
    id: 'ka',
    name: 'Georgian',
    shortName: 'ქარ',
    messages: ka
  },
  {
    id: 'en',
    name: 'English',
    shortName: 'eng',
    messages: en
  }
]

export const getMessages = (locale: string) =>
  locales.find(({ id }) => id === locale)?.messages ?? {}
