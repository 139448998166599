import { Modal as AntModal } from 'antd'
import classNames from 'classnames'
import { ArrowLeft, CloseCircle } from 'iconsax-react'
import { PropsWithChildren, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import IconButton from '../IconButton'

export type ModalProps = PropsWithChildren<{
  className?: string
  width?: number
  hasBack?: number
  title?: ReactNode
  subTitle?: ReactNode
  onClose?: () => void
  onBack?: () => void
  open?: boolean
  onOk?: () => void
  okText?: any
  cancelText?: any
  footer?: any
}>

const Modal = ({
  children,
  footer = null,
  className,
  open = false,
  title,
  subTitle,
  onClose,
  width = 600,
  hasBack,
  onBack,
  onOk,
  okText,
  cancelText
}: ModalProps) => {
  const handleClose = () => {
    onClose?.()
  }

  return (
    <AntModal
      className={classNames(
        '[&_.ant-modal-close:hover]:!bg-gray-300 [&_.ant-modal-close]:!bg-gray-200 [&_.ant-modal-close]:!right-5 [&_.ant-modal-close]:!top-5 [&_.ant-modal-close]:!md:right-5 [&_.ant-modal-close]:!md:top-5 [&_.ant-modal-close]:!text-black [&_.ant-modal-close]:!w-[36px] [&_.ant-modal-close]:!h-[36px] [&_.ant-modal-close]:!rounded-full [&_.ant-modal-content]:!rounded-[12px] [&_.ant-modal-content]:!p-5 [&_.ant-modal-content]:md:!p-[60px] [&_.ant-modal-title]:!font-normal [&_.ant-modal-title]:!text-lg [&_.ant-modal-title]:[&_.ant-modal-title]:text-black [&_.ant-modal-title]:md:!text-2xl [&_.ant-modal-title]:!flex  [&_.ant-modal-title]:!items-center',
        className
      )}
      title={
        <>
          {hasBack && (
            <IconButton
              className="mr-3"
              icon={<ArrowLeft size={20} />}
              size="small"
              type="secondary"
              onClick={onBack}
            />
          )}

          {title}
        </>
      }
      rootClassName="[&_.ant-modal-mask]:!bg-black [&_.ant-modal-mask]:!bg-opacity-60"
      open={open}
      footer={footer !== null ? undefined : null}
      width={width}
      closeIcon={<CloseCircle size={20} />}
      onClose={handleClose}
      onCancel={handleClose}
      onOk={onOk}
      okText={okText}
      cancelText={cancelText}
    >
      {subTitle && (
        <h6 className="pb-4 text-sm text-black md:pb-8">{subTitle}</h6>
      )}

      {children}
    </AntModal>
  )
}

const useStyles = createUseStyles({})

export default Modal
