'use client'

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState
} from 'react'
import { User } from '@/types/user'

export interface LightboxContextType {
  currentIndex: number | null
  setCurrentIndex: (value: number | null) => void
  data: any[] | null
  setData: (data: any[] | null) => void
}

const LightboxContext = createContext<LightboxContextType | undefined>({
  currentIndex: null,
  data: null,
  setCurrentIndex: () => null,
  setData: () => null
})

export const useLightboxContext = () => {
  const context = useContext(LightboxContext)

  if (!context) {
    throw new Error(
      'useLightboxContext must be used within a CurrentUserProvider'
    )
  }
  return context
}

export const LightboxProvider = ({
  children
}: PropsWithChildren<{ user?: User | null }>) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(null)
  const [data, setData] = useState<any[] | null>(null)

  const value: LightboxContextType = {
    currentIndex,
    setCurrentIndex,
    data,
    setData
  }

  return (
    <LightboxContext.Provider value={value}>
      {children}
    </LightboxContext.Provider>
  )
}
