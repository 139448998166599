import { usePageOverlayContext } from '../context/PageOverlayContext'

const usePageOverlay = () => {
  const { isOverlayVisible, toggleOverlay, setIsOverlayVisible } =
    usePageOverlayContext()

  return {
    isOverlayVisible,
    toggleOverlay,
    setIsOverlayVisible
  }
}

export default usePageOverlay
