import { Input as InputAnt, InputProps } from 'antd'
import { TextAreaProps } from 'antd/es/input'
import classNames from 'classnames'
import { CloseCircle, Eye, EyeSlash } from 'iconsax-react'
import { createUseStyles } from 'react-jss'

const Input = ({
  size = 'large',
  className,
  allowClear,
  ...props
}: InputProps) => {
  const classes = useStyles()

  return (
    <InputAnt
      size={size}
      className={classNames(className, classes.input)}
      allowClear={
        allowClear
          ? { clearIcon: <CloseCircle size={16} color="#111722" /> }
          : false
      }
      {...props}
    />
  )
}

const Password = ({
  size = 'large',
  className,
  value,
  allowClear,
  ...props
}: InputProps) => {
  const classes = useStyles()
  return (
    <InputAnt.Password
      size={size}
      value={value}
      className={classNames(className, classes.input)}
      {...props}
      iconRender={(visible) =>
        visible ? (
          <EyeSlash size={16} color="#111722" />
        ) : (
          <Eye color="#111722" size={16} />
        )
      }
      visibilityToggle={!!value}
      allowClear={
        allowClear
          ? { clearIcon: <CloseCircle size={16} color="#111722" /> }
          : false
      }
    />
  )
}

const TextArea = (props: TextAreaProps) => <InputAnt.TextArea {...props} />

Input.Password = Password
Input.TextArea = TextArea

const useStyles = createUseStyles({
  input: {
    '& .ant-input-clear-icon': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .ant-input-password-icon': {
      cursor: 'pointer'
    },
    '& .ant-input-group-addon': {
      borderColor: '#E7E7E9',
      backgroundColor: '#fff!important'
    },
    '& .ant-input-affix-wrapper:not(.ant-input-group-addon+.ant-input-affix-wrapper)':
      {
        borderRight: '0!important',
        '&:hover + .ant-input-group-addon': {
          borderColor: '#5F6774'
        }
      },
    '& .ant-input-affix-wrapper-focused, &.ant-input-affix-wrapper-focused': {
      borderColor: '#5F6774!important'
    },

    '& .ant-input-affix-wrapper-status-error + .ant-input-group-addon, & .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-focused):hover + .ant-input-group-addon':
      {
        borderColor: ' #FF0000!important'
      },
    '& .ant-input-affix-wrapper-focused + .ant-input-group-addon': {
      borderColor: '#5F6774!important',
      borderLeft: 0
    }
  }
})

export default Input
