import { Button as ButtonAnt, ButtonProps } from 'antd'
import classNames from 'classnames'
import { Status } from 'iconsax-react'
import { forwardRef } from 'react'

const Button = forwardRef(
  (
    {
      className,
      size = 'large',
      loading = false,
      disabled,
      children,
      type = 'default',
      ghost,
      ...props
    }: ButtonProps & { loading?: boolean },
    ref: any
  ) => {
    return (
      <ButtonAnt
        ref={ref}
        type={type}
        disabled={disabled || loading}
        size={size}
        ghost={ghost}
        {...props}
        className={classNames(
          '!relative !flex items-center justify-center !border-0 [&.loading>.ant-btn-icon]:!opacity-0 [&>div]:!hidden',

          className,
          {
            loading,
            'pointer-events-none': disabled,
            '!text-transparent': loading,
            '!bg-transparent hover:!text-yellow': type === 'text',
            '!h-auto !bg-transparent !p-0 !text-black hover:!text-yellow':
              type === 'link',
            'bg-gray-200 !shadow-none !outline-none hover:!bg-gray-300':
              type === 'default' && !ghost,
            'bg-transparent !text-black !shadow-none !outline-none hover:!bg-gray-200':
              type === 'default' && ghost,
            'hover:!text-black': type === 'default' && !loading,
            'bg-black': type === 'primary'
          }
        )}
      >
        {loading && (
          <span className="absolute left-0 top-0 !flex h-full w-full items-center justify-center">
            <Status
              size={20}
              className={classNames('animate-spin !text-black')}
            />
          </span>
        )}
        {children}
      </ButtonAnt>
    )
  }
)

Button.displayName = 'Button'

export default Button
