'use client'

import AddToCollectionForm from '@/components/AddToCollectionForm'
import AuthForm from '@/components/AuthForm'
import Button from '@/components/Button'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import HeaderCategories from '@/components/HeaderCategories'
import { Lightbox } from '@/components/Lightbox'
import MobileNavigation from '@/components/MobileNavigation'
import Modal from '@/components/Modal'
import ModalAlert from '@/components/ModalAlert'
import PasswordResetRequestForm from '@/components/PasswordResetRequestForm'
import RegisterForm from '@/components/RegisterForm'
import { ProcessStatus } from '@/components/Status'
import { useBreakpoint } from '@/hooks/useBreakpoints'
import useCategories from '@/hooks/useCategories'
import useCurrentUser from '@/hooks/useCurrentUser'
import { useCurrentUserBrands } from '@/hooks/useCurrentUserBrands'
import { useCurrentUserRevalidate } from '@/hooks/useCurrentUserRevalidate'
import useMobileNavigation from '@/hooks/useMobileNavigation'
import useModal from '@/hooks/useModal'
import useTranslate from '@/hooks/useTranslate'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import createAxiosInstance from '@/services/api'
import notifications from '@/services/notification'
import moment from 'moment'
import 'moment/locale/ka'
import { useLocale } from 'next-intl'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'

export const MainLayout = ({ children }: any) => {
  const {
    isMenuOpen,
    isUserOpen,
    isSearchOpen,
    toggleSearch,
    closeSearch,
    toggleMenu,
    toggleUser,
    closeUser,
    closeMenu
  } = useMobileNavigation()
  const t = useTranslate()
  const { modals, close, open, meta, openAlert, closeAlert } = useModal()
  const [isRegisterLoading, setIsRegisterLoading] = useState(false)
  const [isPwdResetRequestLoading, setIsPwdResetRequestLoading] =
    useState(false)
  const [
    isCreateCollectionAndSaveLoading,
    setIsCreateCollectionAndSaveLoading
  ] = useState(false)

  const collectionsRef = useRef<any>([])
  const locale = useLocale()

  moment.locale(locale)

  const [serverValidationErrors, setServerValidationErrors] = useState<any>()
  const suggestions = [
    'ტყავის ჩანთა',
    'ყავისფერი ჩანთა',
    'მზის სათვალე',
    'მზის სათვალე',
    'მზის სათვალე',
    'მზის სათვალე'
  ]
  const popularSearches = [
    'ტანსაცმელი',
    'აქსესუარი',
    'ბრენდი',
    'ფასდაკლება',
    'ბლოგი'
  ]
  const latestSearches = [
    'ტანსაცმელი',
    'აქსესუარი',
    'ბრენდი',
    'ფასდაკლება',
    'ბლოგი'
  ]

  const collections = [
    { name: 'კოლექციის სახელი 1', id: 1 },
    { name: 'კოლექციის სახელი 2', id: 2 },
    { name: 'კოლექციის სახელი 3', id: 3 },
    { name: 'კოლექციის სახელი 4', id: 4 },
    { name: 'კოლექციის სახელი 5', id: 5 },
    { name: 'კოლექციის სახელი 6', id: 6 },
    { name: 'კოლექციის სახელი 7', id: 7 },
    { name: 'კოლექციის სახელი 8', id: 8 },
    { name: 'კოლექციის სახელი 9', id: 9 },
    { name: 'კოლექციის სახელი 10', id: 10 }
  ]

  const router = useRouter()
  const searchParams = useSearchParams()
  const keyword = searchParams.get('keyword')
  const axios = createAxiosInstance('ka')
  const { isMobile } = useBreakpoint()
  const pathname = usePathname()
  const { categories } = useCategories()
  const { currentUser, loggingOut, setCurrentUser, setLoggingOut } =
    useCurrentUser()
  const brands = useCurrentUserBrands()
  const onSearch = (keyword?: string) => {
    keyword && router.push(`/search?keyword=${keyword}`)

    if (isMobile) {
      closeSearch()
    }
  }

  useUpdateEffect(() => {
    if (currentUser && loggingOut && `/${locale}` === pathname) {
      setCurrentUser(null)
      setLoggingOut(false)
    }
  }, [loggingOut, pathname, locale, currentUser])

  const menuItems = [
    {
      name: t('common.home'),
      url: '/',
      key: 'home'
    },
    {
      name: t('common.products'),
      url: '/categories',
      children: categories,
      key: 'products'
    },
    {
      name: t('common.voucher'),
      url: '/vouchers',
      key: 'vouchers'
    },
    {
      name: t('common.collections'),
      url: '/collections',
      key: 'collections'
    },
    {
      name: t('common.brands'),
      url: '/brands',
      key: 'brands'
    },
    // {
    //   name: t('common.influencers'),
    //   url: '/influencers',
    //   key: 'influencers'
    // },
    {
      name: t('common.sales'),
      url: '/categories?order_by=sales',
      key: 'sales'
    },
    {
      name: t('common.blog'),
      url: '/blog',
      key: 'blog'
    }
  ]

  useCurrentUserRevalidate()

  useUpdateEffect(() => {
    if (currentUser && modals.AUTH) {
      close('AUTH')
    }
  }, [currentUser])

  const handleRegisterSubmit = async (data: any) => {
    setIsRegisterLoading(true)

    try {
      await axios.post('/auth/register', data, {
        timeout: 3000
      })

      setIsRegisterLoading(false)

      openAlert({
        title: t('common.register_succeed'),
        description: (
          <>
            თქვენ წარმატებით დარეგისტრირდით Sachemo-ზე. <br /> ვერიფიკაციის
            ინსტრუქცია გამოიგზავნა თქვენს მეილზე:
            <strong className="inline-block px-1.5">{data.email}.</strong>
            დამატებითი კითხვების შემთხვევაში დაგვიკავშირდით.
          </>
        ),
        status: ProcessStatus.SUCCESS,
        showContact: true
      })
    } catch (error: any) {
      setIsRegisterLoading(false)
      if (error?.response?.data) {
        setServerValidationErrors(error?.response?.data?.message)
      }
    }
  }

  const handleCreateCollectionAndSave = (data: any) => {
    console.log({ data })

    setIsCreateCollectionAndSaveLoading(true)

    setTimeout(() => {
      setIsCreateCollectionAndSaveLoading(false)
      open('ADD_TO_COLLECTION', { isCreateNewCollection: false })
    }, 1000)
  }

  const handleAddToCollection = (collections: any[], data: any) => {
    console.log({ collections, data })

    const isAdded = collectionsRef.current.length < collections.length

    collectionsRef.current = collections

    notifications.success({
      message: data.title,
      description: isAdded
        ? t('common.added_to_collection')
        : t('common.removed_from_collection')
    })
  }

  const handlePwdResetRequestSubmit = async (data: any) => {
    try {
      await axios.post('/auth/reset-password/request', {
        ...data,
        referral: pathname
      })

      setIsPwdResetRequestLoading(false)
      openAlert({
        title: t('common.pwd_reset_link_sent'),
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: t('common.pwd_reset_link_sent_description', {
                email: data.email
              })?.replace(
                data.email,
                `<strong className="inline-block px-1.5">${data.email}</strong>`
              )
            }}
          />
        ),
        status: ProcessStatus.SUCCESS,
        showContact: true
      })
    } catch (error: any) {
      if (error?.response?.data) {
        setServerValidationErrors(error?.response?.data.message)
      }
      setIsPwdResetRequestLoading(false)
    }
  }

  useUpdateEffect(() => setServerValidationErrors({}), [modals])

  useEffect(() => {
    collectionsRef.current = []
  }, [modals.ADD_TO_COLLECTION])

  return (
    <>
      <Lightbox />
      <div className="w-full pt-[65px]">
        <Header
          keyword={keyword as string}
          suggestions={suggestions}
          popularSearches={popularSearches}
          latestSearches={latestSearches}
          className="fixed left-0 top-0 z-[80] w-full sm:z-[60]"
          onSearch={onSearch}
          brands={brands}
        />

        <HeaderCategories items={menuItems} />
        {children}
        <Footer />
        <MobileNavigation
          isMenuOpen={isMenuOpen}
          isUserOpen={isUserOpen}
          isSearchOpen={isSearchOpen}
          onMenuToggle={toggleMenu}
          onUserToggle={toggleUser}
          onMenuClose={closeMenu}
          keyword={keyword as string}
          suggestions={suggestions}
          popularSearches={popularSearches}
          latestSearches={latestSearches}
          onSearch={onSearch}
          onSearchToggle={toggleSearch}
        />

        <Modal
          title={t('common.authorization')}
          subTitle={
            <div className="flex items-center">
              {t('common.or')}
              <Button
                className="text !h-auto !p-0 !pl-1 !text-purple"
                type="text"
                onClick={() => open('REGISTER')}
              >
                {t('common.registration')}
              </Button>
            </div>
          }
          open={modals.AUTH}
          onClose={() => close('AUTH')}
        >
          <AuthForm onPwdReset={() => open('REQUEST_PWD_RESET')} />
        </Modal>

        <Modal
          title={t('common.registration')}
          subTitle={
            <div className="flex items-center">
              {t('common.or')}
              <Button
                className="text !h-auto !p-0 !pl-1 !text-purple"
                type="text"
                htmlType="button"
                onClick={() => open('AUTH')}
              >
                {t('common.authorization')}
              </Button>
            </div>
          }
          open={modals.REGISTER}
          onClose={() => close('REGISTER')}
        >
          <RegisterForm
            onSubmit={handleRegisterSubmit}
            isLoading={isRegisterLoading}
            serverValidationErrors={serverValidationErrors}
          />
        </Modal>

        <Modal
          title={t('common.password_reset')}
          subTitle={
            <div className="flex items-center">
              {t('common.or')}
              <Button
                className="text !h-auto !p-0 !pl-1 !text-purple"
                type="text"
                htmlType="button"
                onClick={() => open('AUTH')}
              >
                {t('common.authorization')}
              </Button>
            </div>
          }
          open={modals.REQUEST_PWD_RESET}
          onClose={() => close('REQUEST_PWD_RESET')}
        >
          <PasswordResetRequestForm
            onSubmit={handlePwdResetRequestSubmit}
            isLoading={isPwdResetRequestLoading}
            serverValidationErrors={serverValidationErrors}
          />
        </Modal>

        <Modal open={modals.ALERT} onClose={() => closeAlert()}>
          <ModalAlert
            title={meta.ALERT?.title}
            description={meta.ALERT?.description}
            status={meta.ALERT?.status}
            showContact={meta.ALERT?.showContact}
          />
        </Modal>
        <Modal
          title={t(
            meta.ADD_TO_COLLECTION?.isCreateNewCollection
              ? 'common.add_collection'
              : 'common.add_to_collection'
          )}
          open={modals.ADD_TO_COLLECTION}
          onClose={() => close('ADD_TO_COLLECTION')}
          hasBack={
            meta.ADD_TO_COLLECTION?.isCreateNewCollection &&
            !!meta.ADD_TO_COLLECTION?.id
          }
          onBack={() =>
            open('ADD_TO_COLLECTION', {
              ...meta.ADD_TO_COLLECTION,
              isCreateNewCollection: false
            })
          }
        >
          <AddToCollectionForm
            collections={collections}
            onCreateAndSave={handleCreateCollectionAndSave}
            onAddToCollection={handleAddToCollection}
            data={meta.ADD_TO_COLLECTION}
            isLoading={isCreateCollectionAndSaveLoading}
            onCreateCollection={() =>
              open('ADD_TO_COLLECTION', {
                ...meta.ADD_TO_COLLECTION,
                isCreateNewCollection: true
              })
            }
            serverValidationErrors={serverValidationErrors}
          />
        </Modal>
      </div>
    </>
  )
}
