import {
  Radio as AntRadio,
  RadioGroupProps as AntRadioGroupProps,
  RadioProps as AntRadioProps
} from 'antd'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { createUseStyles } from 'react-jss'

export type RadioProps = PropsWithChildren<{}> & AntRadioProps
export type RadioGroupProps = PropsWithChildren<{
  alignStyle?: 'default' | 'spaceBetween'
  direction?: 'horizontal' | 'vertical'
  bordered?: boolean
}> &
  AntRadioGroupProps

const RadioGroup = ({
  className,
  alignStyle,
  direction = 'horizontal',
  bordered,
  ...props
}: RadioGroupProps) => {
  const classes = useStyles()

  return (
    <AntRadio.Group
      {...props}
      className={classNames(className, classes.radioGroup, {
        '[&_.ant-radio-button-wrapper:before]:!hidden [&_.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked)]:!border-gray-300 [&_.ant-radio-button-wrapper-checked]:!border-black [&_.ant-radio-button-wrapper]:!text-black [&_.ant-radio-button-wrapper]:!border-s-[1px] [&_.ant-radio-button-wrapper]:!rounded-md [&_.ant-radio-button-wrapper+.ant-radio-button-wrapper]:!ml-2':
          alignStyle === 'spaceBetween',
        '!flex !flex-col': direction === 'vertical',
        '[&_.ant-radio-wrapper+.ant-radio-wrapper]:!mt-2 [&_.ant-radio-wrapper]:!px-4 [&_.ant-radio-wrapper]:!py-3 [&_.ant-radio-wrapper]:!rounded-lg [&_.ant-radio-wrapper]:!border [&_.ant-radio-wrapper]:!border-gray-300':
          bordered
      })}
    />
  )
}

const Radio = ({ className, ...props }: RadioProps) => {
  const classes = useStyles()

  return (
    <AntRadio {...props} className={classNames(className, classes.radio)} />
  )
}

Radio.Group = RadioGroup

const useStyles = createUseStyles({
  radio: {
    '& .ant-radio-inner': {
      position: 'relative',

      '&:after': {
        content: "''",
        position: 'absolute',
        transform: 'rotate(45deg)!important',
        height: 10,
        width: 5,
        borderBottom: '1.5px solid #fff',
        borderRight: '1.5px solid #fff',
        background: 'transparent',
        borderRadius: 0,
        left: 16,
        top: 13,
        display: 'block'
      }
    }
  },
  radioGroup: {
    '& .ant-radio-wrapper': {
      paddingTop: 4,
      paddingBottom: 4
    },
    '& .ant-radio-inner': {
      position: 'relative',

      '&:after': {
        content: "''",
        position: 'absolute',
        transform: 'rotate(45deg)!important',
        height: 10,
        width: 5,
        borderBottom: '1.5px solid #fff',
        borderRight: '1.5px solid #fff',
        background: 'transparent',
        borderRadius: 0,
        left: 16,
        top: 13,
        display: 'block'
      }
    }
  }
})

export default Radio
