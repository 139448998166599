import { Dropdown as AntDropdown, DropDownProps } from 'antd'
import classNames from 'classnames'

const Dropdown = ({ className, rootClassName, ...props }: DropDownProps) => {
  return (
    <AntDropdown
      {...props}
      className={classNames(className)}
      rootClassName={classNames(
        'bg-white !p-5 border border-gray-300',
        rootClassName
      )}
    />
  )
}

export default Dropdown
