import createAxiosInstance from '@/services/api'
import useSWRMutation from 'swr/mutation'

type Events<T> = {
  onFinish?: (data: T) => void
}

export const useDelete = <T>(
  url: string,
  body: any = null,
  options?: { multipart?: boolean }
) => {
  const axios = createAxiosInstance(undefined, options)
  const poster = (url: string) => {
    return axios.delete(url, { data: body }).then((res) => res.data)
  }
  const { data, error, isMutating, trigger } = useSWRMutation<T>(url, poster)

  return {
    data,
    isLoading: isMutating,
    isError: !!error,
    error,
    trigger
  }
}
