import createAxiosInstance from '@/services/api'
import useSWRMutation from 'swr/mutation'

type Events<T> = {
  onFinish?: (data: T) => void
}

export const usePut = <T>(
  url: string,
  requestData?: any,
  options?: { multipart?: boolean }
) => {
  const axios = createAxiosInstance(undefined, options)
  const updater = (url: string, body: any) =>
    axios.put(url, requestData ?? body?.arg).then((res) => res.data)
  const { data, error, isMutating, trigger } = useSWRMutation<T>(url, updater)

  return {
    data,
    isLoading: isMutating,
    isError: error,
    error,
    trigger
  }
}
