import {
  Checkbox as AntCheckbox,
  CheckboxProps as AntCheckboxProps
} from 'antd'
import { CheckboxGroupProps as AntCheckboxGroupProps } from 'antd/es/checkbox'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { createUseStyles } from 'react-jss'

export type CheckboxProps = PropsWithChildren<
  {
    uppercase?: boolean
  } & AntCheckboxProps
>

export type CheckboxGroupProps = PropsWithChildren<
  {
    uppercase?: boolean
  } & AntCheckboxGroupProps
>

const CheckboxGroup = ({
  uppercase,
  className,
  ...props
}: CheckboxGroupProps) => {
  const classes = useStyles()

  return (
    <AntCheckbox.Group
      {...props}
      className={classNames(className, {
        // [classes.uppercase]: uppercase
      })}
    />
  )
}

const Checkbox = ({ uppercase, className, ...props }: CheckboxProps) => {
  const classes = useStyles()

  return (
    <AntCheckbox
      {...props}
      className={classNames(className, {
        // [classes.uppercase]: uppercase
      })}
    />
  )
}

Checkbox.Group = CheckboxGroup

const useStyles = createUseStyles({
  uppercase: {
    '& .ant-checkbox-wrapper .ant-checkbox + span': {
      'font-feature-settings': "'case' on"
    }
  }
})

export default Checkbox
