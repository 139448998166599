import { Product } from '@/types/product'
import { faker } from '@faker-js/faker'

export function createRandomProduct(): Product {
  return {
    id: faker.number.int(),
    title: faker.commerce.productName() + ' ' + faker.commerce.productName(),
    status: {
      id: 1,
      name: 'ახალი'
    },
    product_id: faker.number.int({ min: 1000, max: 5000 }),
    brand: {
      assets: [],
      name: faker.company.name(),
      id: faker.number.int(),
      image: null,
      balance: faker.number.int({
        min: 1000,
        max: 10000
      }),
      company_type_id: 1,
      status_id: 1,
      brand_id: faker.number.int({ min: 1000, max: 5000 }),
      slug: faker.company.name().toLocaleLowerCase().replaceAll(' ', '-'),
      company_name: faker.company.name(),
      slogan: faker.company.name(),
      identification_number: faker.number.int({ min: 1000, max: 5000 }),
      status: {
        id: 1,
        name: 'ახალი'
      },
      created_at: new Date()
    },
    assets: [
      {
        type: 'image',
        src: faker.image.urlPicsumPhotos({ width: 400, height: 400 }),
        primary: true
      },
      {
        type: 'image',
        src: faker.image.urlPicsumPhotos({ width: 400, height: 400 }),
        primary: false
      }
    ],
    price: parseFloat(faker.commerce.price({ min: 100, max: 600 })),
    discountPercentage: faker.number.int({
      min: 0,
      max: 30
    })
  }
}

export enum ProductStockStatus {
  InStock = 'in-stock',
  OutOfStock = 'out-of-stock',
  RunningOut = 'running-out',
  Orderable = 'orderable'
}

export enum ProductStockTypes {
  ByOrder = 1,
  Quantity = 2
}

export enum ProductStatus {
  Published = 1,
  Draft = 2,
  PendingReview = 3,
  Suspended = 4,
  Archived = 5
}

export const PRODUCT_IMG_SIZE_LIMIT_IN_BYTES = 1048576 * 2
export const PRODUCT_RUNNING_OUT_QUANTITY = 5
export const PRODUCTS_PER_PAGE = 12
