'use client'

import classNames from 'classnames'
import { ArrowRight } from 'iconsax-react'
import { ReactNode } from 'react'
import Link from '../Link'

export type CardType = 'vertical' | 'horizontal' | 'slot'

export type CardProps = {
  type?: CardType
  title?: string
  label?: string | ReactNode
  onClick?: null | (() => void)
  href?: string
  className?: string
  icon: ReactNode
  imageClassName?: string
}

const Card = ({
  type = 'horizontal',
  title,
  label,
  onClick = null,
  className,
  icon,
  imageClassName,
  href
}: CardProps) => {
  const HorizontalCard = () => (
    <div
      onClick={onClick ?? undefined}
      className={classNames(
        'group flex items-center rounded-lg bg-gray-200 p-4 font-firago transition-all ease-in-out sm:p-8',
        {
          'cursor-pointer border hover:border-gray-300 active:border-transparent active:bg-black hover:bg-black':
            onClick || !!href,
          'flex-col': type === 'vertical'
        },
        className
      )}
    >
      <span
        className={classNames(
          'flex h-14 w-14 items-center	justify-center overflow-hidden rounded-full bg-white',
          imageClassName
        )}
      >
        {icon}
      </span>
      <div
        className={classNames('pl-4', {
          'transition-all ease-in-out group-active:text-white group-hover:text-white':
            onClick || !!href,
          'pt-4': type === 'vertical',
          'pl-4': type === 'horizontal'
        })}
      >
        <h5 className="font-medium">{title}</h5>
        <h6 className="text-sm font-light">{label}</h6>
      </div>
      {(onClick || !!href) && type === 'horizontal' && (
        <ArrowRight
          size={24}
          className={classNames('ml-auto', {
            ' group-hover:text-white transform transition-all ease-in-out group-active:text-white max-md:opacity-1 md:-translate-x-2 md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100':
              onClick || !!href
          })}
        />
      )}
    </div>
  )

  return type === 'slot' ? (
    <span
      className={classNames(
        'relative flex h-14 w-14 items-center justify-center overflow-hidden rounded-full bg-white',
        {
          'cursor-pointer': onClick || !!href
        },
        className
      )}
      onClick={onClick ?? undefined}
    >
      {icon}
    </span>
  ) : !!href ? (
    <Link styled={false} href={href}>
      <HorizontalCard />
    </Link>
  ) : (
    <HorizontalCard />
  )
}

export default Card
