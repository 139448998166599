import { Divider as DividerAnt, DividerProps } from 'antd'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'

const Divider = ({ className, ...props }: DividerProps) => {
  const classes = useStyles()

  return (
    <DividerAnt
      className={classNames(className, 'border-gray-300')}
      {...props}
    />
  )
}

const useStyles = createUseStyles({})

export default Divider
