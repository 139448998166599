import { useModalContext } from '../context/ModalContext'

const useModal = () => {
  const { close, open, closeAll, modals, meta, openAlert, closeAlert } =
    useModalContext()

  return {
    close,
    open,
    closeAll,
    modals,
    meta,
    openAlert,
    closeAlert
  }
}

export default useModal
