'use client'

import { useDelete } from '@/hooks/useDelete'
import { usePost } from '@/hooks/usePost'
import { usePut } from '@/hooks/usePut'
import useTranslate from '@/hooks/useTranslate'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { SingleResponse } from '@/types/api'
import { Cart } from '@/types/cart'
import { message } from 'antd'
import { PropsWithChildren, createContext, useContext, useState } from 'react'

export interface CartContextType {
  cart: Cart | null | undefined
  visible: boolean
  cartLineUpdating: boolean
  addingToCart: boolean
  cartLineRemoving: boolean
  cartClearing: boolean
  setCart: (data: Cart | null) => void
  setCartVisible: (value: boolean) => void
  updateLineItem: (id: string, quantity: number) => void
  removeLineItem: (id: string) => void
  clearCart: () => void
  addToCart: (payload: any) => void
}

const CartContext = createContext<CartContextType | undefined>({
  cart: null,
  visible: false,
  cartLineUpdating: false,
  cartLineRemoving: false,
  cartClearing: false,
  addingToCart: false,
  setCart: () => null,
  setCartVisible: () => null,
  updateLineItem: (id: string, quantity: number) => null,
  removeLineItem: (id: string) => null,
  clearCart: () => null,
  addToCart: (payload: any) => null
})

export const useCartContext = () => {
  const context = useContext(CartContext)

  if (!context) {
    throw new Error('useCartContext must be used within a CartProvider')
  }
  return context
}

export const CartProvider = ({
  cart: initialCart,
  children
}: PropsWithChildren<{ cart?: Cart | null | undefined }>) => {
  const [cart, setCart] = useState<Cart | null | undefined>(initialCart)
  const [lineId, setLineId] = useState<string>()
  const [visible, setCartVisible] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()
  const t = useTranslate()
  const {
    isLoading: cartLineUpdating,
    trigger: triggerCartLineUpdate,
    data: updateCartLineResponse,
    error: updateCartLineError
  } = usePut<SingleResponse<Cart>>(`/cart/${cart?.id}/line/${lineId}`)

  const {
    isLoading: cartLineRemoving,
    trigger: triggerCartLineRemove,
    data: removeCartLineResponse,
    error: removeCartLineError
  } = useDelete<SingleResponse<Cart>>(`/cart/${cart?.id}/line/${lineId}`)

  const {
    isLoading: cartClearing,
    trigger: triggerCartClear,
    data: clearCartResponse,
    error: clearCartError
  } = useDelete<SingleResponse<Cart>>(`/cart/${cart?.id}/line`)

  const {
    isLoading: addingToCart,
    trigger: addToCart,
    data: addToCartResponse,
    error: addToCartError
  } = usePost<SingleResponse<any>>(`/cart/${cart?.id}/line`, {
    multipart: false
  })

  const updateLineItem = (id: string, quantity: number) => {
    setLineId(id)

    if (quantity === 0 && cart) {
      cart.lines = cart.lines?.filter(({ id }: any) => id !== id)

      setCart(cart)
    }

    const body: any = { quantity }

    setTimeout(() => {
      triggerCartLineUpdate(body)
    }, 200)
  }

  const removeLineItem = (id: string) => {
    setLineId(id)

    setTimeout(() => {
      triggerCartLineRemove()
    }, 200)
  }

  const clearCart = () => {
    triggerCartClear()
  }

  useUpdateEffect(() => {
    if (updateCartLineResponse) {
      setCart(updateCartLineResponse.data)

      messageApi.success(t('common.cart_updated'))
    }
  }, [updateCartLineResponse])

  useUpdateEffect(() => {
    if (removeCartLineResponse) {
      setCart(removeCartLineResponse.data)

      messageApi.success(t('common.cart_updated'))
    }
  }, [removeCartLineResponse])

  useUpdateEffect(() => {
    if (clearCartResponse) {
      setCart(clearCartResponse.data)

      messageApi.success(t('common.cart_cleared'))
    }
  }, [clearCartResponse])

  useUpdateEffect(() => {
    if (addToCartResponse && addToCartResponse.data) {
      messageApi.success(t('common.added_to_cart'))

      setCartVisible(true)

      setCart(addToCartResponse.data)
    }
  }, [addToCartResponse])

  useUpdateEffect(() => {
    if (addToCartError) {
      messageApi.error(t('common.error_adding_to_cart'))
    }
  }, [addToCartError])

  const value: CartContextType = {
    setCart,
    setCartVisible,
    updateLineItem,
    removeLineItem,
    clearCart,
    addToCart,
    addingToCart,
    cart,
    visible,
    cartLineUpdating,
    cartLineRemoving,
    cartClearing
  }

  return (
    <CartContext.Provider value={value}>
      {contextHolder}
      {children}
    </CartContext.Provider>
  )
}
