'use client'

import { PropsWithChildren, createContext, useContext, useState } from 'react'

export interface CategoriesContextType {
  categories: any[]
}

const CategoriesContext = createContext<CategoriesContextType | undefined>({
  categories: []
})

export const useCategoriesContext = () => {
  const context = useContext(CategoriesContext)

  if (!context) {
    throw new Error(
      'useCategoriesContext must be used within a CategoriesProvider'
    )
  }
  return context
}

export const CategoriesProvider = ({
  categories: initialCategories,
  children
}: PropsWithChildren<{ categories: any[] }>) => {
  const [categories, setCategories] = useState<any[]>(initialCategories)

  const value: CategoriesContextType = {
    categories
  }

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  )
}
